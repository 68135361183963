import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row justify-between items-center w-full" }
const _hoisted_2 = { class: "flex flex-row items-center" }
const _hoisted_3 = { class: "flex flex-row items-center" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_5 = { class: "flex bg-indigo-50 border border-primary-purple rounded-tl-md rounded-bl-md my-calendar" }
const _hoisted_6 = {
  key: 0,
  class: "px-2 py-1"
}
const _hoisted_7 = {
  key: 1,
  class: "px-2 py-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_VDatePicker = _resolveComponent("VDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseButton, {
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectDate(1))),
        class: _normalizeClass([_ctx.fixDateButton === 1 ? 'bg-gray-100' : '', "px-4 py-2 border border-gray-200 rounded-tl-md rounded-bl-md"])
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("12 months")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_BaseButton, {
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectDate(2))),
        class: _normalizeClass([_ctx.fixDateButton === 2 ? 'bg-gray-100' : '', "px-4 py-2 border-t border-b border-r border-gray-200"])
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode("30 days")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_BaseButton, {
        type: "button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectDate(3))),
        class: _normalizeClass([_ctx.fixDateButton === 3 ? 'bg-gray-100' : '', "px-4 py-2 border-t border-b border-gray-200"])
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("7 days")
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_BaseButton, {
        type: "button",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectDate(4))),
        class: _normalizeClass([_ctx.fixDateButton === 4 ? 'bg-gray-100' : '', "px-4 py-2 border border-gray-200 rounded-tr-md rounded-br-md"])
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("24 hours")
        ])),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.customDate.start !== null && _ctx.customDate.end !== null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "text-primary-purple mr-4",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectDate(5)))
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("Apply")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(), _createBlock(_component_VDatePicker, {
          key: _ctx.datePickerKey,
          modelValue: _ctx.customDate,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customDate) = $event)),
          popover: _ctx.popover,
          "is-range": "",
          color: "purple"
        }, {
          default: _withCtx(({ inputValue, inputEvents }) => [
            _createElementVNode("div", _mergeProps({ class: "flex justify-center items-center gap-0" }, _toHandlers(inputEvents.start || inputEvents.end, true)), [
              (_ctx.customDate.start === null || _ctx.customDate.end === null)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "Select date"))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.convertDateToMDY(inputValue.start)) + " to " + _toDisplayString(_ctx.convertDateToMDY(inputValue.end)), 1))
            ], 16)
          ]),
          _: 1
        }, 8, ["modelValue", "popover"]))
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_BaseButton, {
          type: "button",
          onClick: _ctx.clearDate,
          class: "px-2 py-1 items-center justify-center bg-indigo-50 border-t border-b border-r border-primary-purple rounded-tr-md rounded-br-md"
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("span", { class: "text-primary-purple border border-primary-purple w-4 h-4 text-xs my-1 flex items-center justify-center rounded-full" }, " x ", -1)
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ])
  ]))
}