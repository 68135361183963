import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2 justify-start items-start" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-wrap gap-2" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "flex flex-wrap gap-2" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 0,
  class: "flex flex-row gap-2"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }
const _hoisted_11 = {
  key: 1,
  class: "flex flex-col gap-2 mt-4"
}
const _hoisted_12 = { class: "flex flex-row items-center gap-2" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          loading: "lazy",
          src: _ctx.programLogo,
          alt: "program logo",
          class: "w-[45px] h-[45px] object-cover rounded-full"
        }, null, 8, _hoisted_3)
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex flex-col" }, [
      _createElementVNode("span", { class: "text-gray-900 font-sans text-2xl font-bold leading-7" }, "Meeting with Expert")
    ], -1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", { innerHTML: _ctx.CLOCK_ICON }, null, 8, _hoisted_5),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }, "30 min", -1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", { innerHTML: _ctx.PHONE_ICON }, null, 8, _hoisted_7),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-900/60 font-sans text-base font-bold leading-6" }, "Phone call", -1))
    ]),
    (_ctx.hasDateAndTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", { innerHTML: _ctx.CALENDAR_ICON }, null, 8, _hoisted_9),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.dateAndTime), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.previousMeeting)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-primary-purple bg-primary-purple/10 px-2 py-1 rounded-md font-sans text-base font-bold leading-6" }, "Previous booking:", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", { innerHTML: _ctx.CALENDAR_ICON }, null, 8, _hoisted_13),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.previousMeeting), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}