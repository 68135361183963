import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex flex-grow max-md:flex-col" }
const _hoisted_3 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_4 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_5 = { class: "flex flex-col justify-start w-4/5 max-md:ml-0 max-md:w-full" }
const _hoisted_6 = { class: "flex flex-col mt-20 w-full max-md:mt-10 max-md:max-w-full" }
const _hoisted_7 = { class: "flex flex-col ml-16 max-w-full" }
const _hoisted_8 = { class: "flex justify-center mb-4" }
const _hoisted_9 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_10 = {
  key: 0,
  class: "self-center mt-8 w-full px-10"
}
const _hoisted_11 = { class: "flex gap-5 max-md:flex-col" }
const _hoisted_12 = { class: "flex flex-col w-[63%] max-md:ml-0 max-md:w-full" }
const _hoisted_13 = {
  id: "previewContainer",
  class: "flex flex-col w-full mx-auto w-full bg-gray-200 max-md:mt-8 max-md:max-w-full pb-4 overflow-hidden"
}
const _hoisted_14 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_15 = { class: "flex flex-col ml-5 w-[37%] max-md:ml-0 max-md:w-full" }
const _hoisted_16 = { class: "flex flex-col max-md:mt-8" }
const _hoisted_17 = { class: "flex-flex-col" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "flex flex-wrap justify-center mt-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_CurrentStep = _resolveComponent("CurrentStep")!
  const _component_TopHeaderBar = _resolveComponent("TopHeaderBar")!
  const _component_PopupModal = _resolveComponent("PopupModal")!
  const _component_TopHeaderBarDesign = _resolveComponent("TopHeaderBarDesign")!
  const _component_PopupModalDesign = _resolveComponent("PopupModalDesign")!
  const _component_GetWidgetCode = _resolveComponent("GetWidgetCode")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
            _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
              _createTextVNode(" Get Started "),
              _createElementVNode("br")
            ])
          ], -1)),
          _createVNode(_component_StepsList, { currentStep: 3 })
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "self-start text-3xl font-semibold leading-tight text-zinc-800" }, " Set-up Widget ", -1)),
            _createVNode(_component_CurrentStep, {
              currentStep: _ctx.currentStep,
              firstStepText: "Setup Widget"
            }, null, 8, ["currentStep"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (!_ctx.userStore.createExpertProgram?.widgetCreated)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("section", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      (_ctx.topHeaderBarVisible)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_TopHeaderBar, {
                              topheaderText: _ctx.topheaderText,
                              buttonText: _ctx.buttonText,
                              buttonTextColor: _ctx.buttonTextColor,
                              fontSize: _ctx.fontSize,
                              fontFamily: _ctx.fontFamily,
                              fontBoldness: _ctx.boldness,
                              headerBackgroundColor: _ctx.headerBackgroundColor,
                              headerTextColor: _ctx.headerTextColor,
                              buttonBackgrounColor: _ctx.headerButtonBackgroundColor,
                              expertsDirectoryUrl: _ctx.topHeaderExpertsDirectoryUrl,
                              disableCloseButton: true
                            }, null, 8, ["topheaderText", "buttonText", "buttonTextColor", "fontSize", "fontFamily", "fontBoldness", "headerBackgroundColor", "headerTextColor", "buttonBackgrounColor", "expertsDirectoryUrl"])
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.popupModalVisible)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["flex flex-col", {
                      'items-end': !_ctx.popupModalLeftOrientation,
                      'items-start': _ctx.popupModalLeftOrientation,
                    }]),
                            style: { marginTop: '40%' }
                          }, [
                            _createVNode(_component_PopupModal, {
                              fontFamily: _ctx.popupModalfontFamily,
                              fontSize: _ctx.popupModalfontSize,
                              fontBoldness: _ctx.popupModalBoldness,
                              expertsDirectoryUrl: _ctx.popupModalExpertsDirectoryUrl,
                              disableCloseButton: true,
                              buttonTextColor: _ctx.popupModalButtonTextColor,
                              buttonText: _ctx.popupModalButtonText,
                              bodyText: _ctx.popupModalBodyText,
                              buttonBackgroundColor: _ctx.popupModalButtonBackgroundColor,
                              textColor: _ctx.popupModalTextColor,
                              bannerBackgroundColor: _ctx.popupModalBannerBackgroundColor
                            }, null, 8, ["fontFamily", "fontSize", "fontBoldness", "expertsDirectoryUrl", "buttonTextColor", "buttonText", "bodyText", "buttonBackgroundColor", "textColor", "bannerBackgroundColor"])
                          ], 2))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("section", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_TopHeaderBarDesign, {
                          isVisible: _ctx.topHeaderBarVisible,
                          fontFamily: _ctx.fontFamily,
                          fontSize: _ctx.fontSize,
                          buttonTextColor: _ctx.buttonTextColor,
                          boldness: _ctx.boldness,
                          topHeaderBackgroundColor: _ctx.headerBackgroundColor,
                          topHeaderbuttonBackgroundColor: 
                        _ctx.headerButtonBackgroundColor
                      ,
                          topHeaderTextColor: _ctx.headerTextColor,
                          headerText: _ctx.topheaderText,
                          buttonText: _ctx.buttonText,
                          topHeaderExpertsDirectoryUrl: 
                        _ctx.topHeaderExpertsDirectoryUrl
                      ,
                          onExpertsDirectoryUrlChanged: 
                        _ctx.handleTopHeaderExpertsDirectoryUrlChange
                      ,
                          onToggleVisibility: _ctx.handleTopHeaderBarVisibilityChange,
                          onButtonTextChanged: _ctx.handleButtonTextChange,
                          onFontFamilyChanged: _ctx.handleFontChange,
                          onBoldnessChanged: _ctx.handleBoldnessChange,
                          onButtonTextColorChanged: _ctx.handleButtonTextColorChange,
                          onHeaderBackgroundColorChanged: 
                        _ctx.handleHeaderBackgroundColorChange
                      ,
                          onButtonBackgroundColorChanged: 
                        _ctx.handleButtonBackgroundColorChange
                      ,
                          onHeaderTextColorChanged: _ctx.handleHeaderTextColorChange,
                          onFontSizeChanged: _ctx.handleFontSizeChange,
                          onHeaderTextChanged: _ctx.handleHeaderTextChange
                        }, null, 8, ["isVisible", "fontFamily", "fontSize", "buttonTextColor", "boldness", "topHeaderBackgroundColor", "topHeaderbuttonBackgroundColor", "topHeaderTextColor", "headerText", "buttonText", "topHeaderExpertsDirectoryUrl", "onExpertsDirectoryUrlChanged", "onToggleVisibility", "onButtonTextChanged", "onFontFamilyChanged", "onBoldnessChanged", "onButtonTextColorChanged", "onHeaderBackgroundColorChanged", "onButtonBackgroundColorChanged", "onHeaderTextColorChanged", "onFontSizeChanged", "onHeaderTextChanged"]),
                        _createVNode(_component_PopupModalDesign, {
                          isVisible: _ctx.popupModalVisible,
                          fontFamily: _ctx.popupModalfontFamily,
                          fontSize: _ctx.popupModalfontSize,
                          boldness: _ctx.popupModalBoldness,
                          popupButtonTextColor: _ctx.popupModalButtonTextColor,
                          popupButtonText: _ctx.popupModalButtonText,
                          popupBodyText: _ctx.popupModalBodyText,
                          popupExpertsDirectoryUrl: _ctx.popupModalExpertsDirectoryUrl,
                          popupLeftOrientation: _ctx.popupModalLeftOrientation,
                          popupButtonBackgroundColor: 
                        _ctx.popupModalButtonBackgroundColor
                      ,
                          popupTextColor: _ctx.popupModalTextColor,
                          popupBannerBackgroundColor: 
                        _ctx.popupModalBannerBackgroundColor
                      ,
                          onToggleVisibility: _ctx.handlePopupModalVisibilityChange,
                          onFontFamilyChanged: _ctx.handlePopupModalFontChange,
                          onBoldnessChanged: _ctx.handlePopupModalBoldnessChange,
                          onFontSizeChanged: _ctx.handlePopupModalFontSizeChange,
                          onLeftOrientationChanged: 
                        _ctx.handlePopupModalLeftOrientationChange
                      ,
                          onButtonTextColorChanged: 
                        _ctx.handlePopupModalButtonTextColorChange
                      ,
                          onButtonTextChanged: _ctx.handlePopupModalButtonTextChange,
                          onBodyTextChanged: _ctx.handlePopupModalBodyTextChange,
                          onExpertsDirectoryUrlChanged: 
                        _ctx.handlePopupModalExpertsDirectoryUrlChange
                      ,
                          onButtonBackgroundColorChanged: 
                        _ctx.handlePopupModalButtonBackgroundColorChange
                      ,
                          onTextColorChanged: _ctx.handlePopupModalTextColorChanged,
                          onBannerBackgroundColorChanged: 
                        _ctx.handlePopupModalBannerBackgroundColorChange
                      
                        }, null, 8, ["isVisible", "fontFamily", "fontSize", "boldness", "popupButtonTextColor", "popupButtonText", "popupBodyText", "popupExpertsDirectoryUrl", "popupLeftOrientation", "popupButtonBackgroundColor", "popupTextColor", "popupBannerBackgroundColor", "onToggleVisibility", "onFontFamilyChanged", "onBoldnessChanged", "onFontSizeChanged", "onLeftOrientationChanged", "onButtonTextColorChanged", "onButtonTextChanged", "onBodyTextChanged", "onExpertsDirectoryUrlChanged", "onButtonBackgroundColorChanged", "onTextColorChanged", "onBannerBackgroundColorChanged"])
                      ])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_GetWidgetCode)
              ]))
        ]),
        _createElementVNode("footer", _hoisted_19, [
          _createVNode(_component_FooterSection, {
            isDisabled: _ctx.isDisabled,
            onNext: _ctx.goNext
          }, null, 8, ["isDisabled", "onNext"])
        ])
      ])
    ])
  ]))
}