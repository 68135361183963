<template>
  <div class="flex flex-row justify-between items-center w-full">
    <div class="flex flex-row items-center">
      <BaseButton
        type="button"
        @click="selectDate(1)"
        :class="fixDateButton === 1 ? 'bg-gray-100' : ''"
        class="px-4 py-2 border border-gray-200 rounded-tl-md rounded-bl-md"
        >12 months</BaseButton
      >
      <BaseButton
        type="button"
        @click="selectDate(2)"
        :class="fixDateButton === 2 ? 'bg-gray-100' : ''"
        class="px-4 py-2 border-t border-b border-r border-gray-200"
        >30 days</BaseButton
      >
      <BaseButton
        type="button"
        @click="selectDate(3)"
        :class="fixDateButton === 3 ? 'bg-gray-100' : ''"
        class="px-4 py-2 border-t border-b border-gray-200"
        >7 days</BaseButton
      >
      <BaseButton
        type="button"
        @click="selectDate(4)"
        :class="fixDateButton === 4 ? 'bg-gray-100' : ''"
        class="px-4 py-2 border border-gray-200 rounded-tr-md rounded-br-md"
        >24 hours</BaseButton
      >
    </div>
    <div class="flex flex-row items-center">
      <div
        class="flex flex-col"
        v-if="customDate.start !== null && customDate.end !== null"
      >
        <BaseButton
          type="button"
          class="text-primary-purple mr-4"
          @click="selectDate(5)"
          >Apply</BaseButton
        >
      </div>
      <div
        class="flex bg-indigo-50 border border-primary-purple rounded-tl-md rounded-bl-md my-calendar"
      >
        <VDatePicker
          :key="datePickerKey"
          v-model="customDate"
          :popover="popover"
          is-range
          color="purple"
        >
          <template #default="{ inputValue, inputEvents }">
            <div
              class="flex justify-center items-center gap-0"
              v-on="inputEvents.start || inputEvents.end"
            >
              <span
                v-if="customDate.start === null || customDate.end === null"
                class="px-2 py-1"
                >Select date</span
              >
              <span v-else class="px-2 py-1"
                >{{ convertDateToMDY(inputValue.start) }} to
                {{ convertDateToMDY(inputValue.end) }}</span
              >
            </div>
          </template>
        </VDatePicker>
      </div>
      <div>
        <BaseButton
          type="button"
          @click="clearDate"
          class="px-2 py-1 items-center justify-center bg-indigo-50 border-t border-b border-r border-primary-purple rounded-tr-md rounded-br-md"
          ><span
            class="text-primary-purple border border-primary-purple w-4 h-4 text-xs my-1 flex items-center justify-center rounded-full"
          >
            x
          </span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "DateFilters",
  components: {
    BaseButton,
  },
  props: {
    applyFilter: {
      type: Function,
      required: true,
    },
    handleError: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const fixDateButton = ref(1);
    const customDate = ref({
      start: null,
      end: null,
    });
    const popover = ref(true);
    const datePickerKey = ref(0);

    const clearDate = () => {
      customDate.value.start = null;
      customDate.value.end = null;
      datePickerKey.value++;
    };

    const selectDate = (type: number) => {
      if (type === 1) {
        fixDateButton.value = 1;
        twelveMonths();
      } else if (type === 2) {
        fixDateButton.value = 2;
        thirtyDays();
      } else if (type === 3) {
        fixDateButton.value = 3;
        sevenDays();
      } else if (type === 4) {
        fixDateButton.value = 4;
        twentyFourHours();
      } else if (type === 5) {
        fixDateButton.value = 0;
        customDateFilter();
      }
    };

    const twelveMonths = () => {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setMonth(startDate.getMonth() - 11);
      props.applyFilter(startDate, endDate, "month");
    };

    const thirtyDays = () => {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 30);
      props.applyFilter(startDate, endDate, "day");
    };

    const sevenDays = () => {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setDate(startDate.getDate() - 7);
      props.applyFilter(startDate, endDate, "day");
    };

    const twentyFourHours = () => {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setHours(startDate.getHours() - 24);
      props.applyFilter(startDate, endDate, "day");
    };

    const customDateFilter = () => {
      if (customDate.value.start && customDate.value.end) {
        const diffInDays = calculateDateDifferenceInDays(
          customDate.value.start,
          customDate.value.end
        );
        if (diffInDays > 31) {
          props.applyFilter(
            customDate.value.start,
            customDate.value.end,
            "month"
          );
        } else {
          props.applyFilter(
            customDate.value.start,
            customDate.value.end,
            "day"
          );
        }
      } else {
        props.handleError("Custom dates incomplete.");
      }
    };

    const calculateDateDifferenceInDays = (
      date1: Date,
      date2: Date
    ): number => {
      const msInDay = 24 * 60 * 60 * 1000;
      const diffInMs = Math.abs(date1.getTime() - date2.getTime());
      const diffInDays = diffInMs / msInDay;

      return diffInDays;
    };

    const convertDateToMDY = (dateString: string): string => {
      const [day, month, year] = dateString.split("/");
      const newDateString = `${month}/${day}/${year}`;

      return newDateString;
    };

    onMounted(() => {
      selectDate(1);
    });

    return {
      fixDateButton,
      customDate,
      popover,
      clearDate,
      datePickerKey,
      selectDate,
      convertDateToMDY,
    };
  },
});
</script>

<style scoped>
.my-calendar :deep(.vc-base-icon) {
  color: #6a48f3;
  background-color: rgba(106, 72, 243, 0.08);
  border-radius: 9999px;
}

.my-calendar :deep(.vc-nav-item.is-active) {
  background-color: #6a48f3;
}

.my-calendar :deep(.vc-nav-item.is-current) {
  color: #6a48f3;
}
</style>
