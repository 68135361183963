import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col min-h-screen bg-gray-100" }
const _hoisted_2 = { class: "flex flex-col w-full border-b" }
const _hoisted_3 = { class: "flex flex-col gap-6 mb-16 p-6" }
const _hoisted_4 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_5 = { class: "flex flex-col ml-4" }
const _hoisted_6 = { class: "flex flex-wrap justify-between items-center" }
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_8 = { class: "flex flex-col text-left" }
const _hoisted_9 = {
  key: 1,
  class: "flex flex-wrap gap-4 items-center"
}
const _hoisted_10 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_11 = { class: "flex flex-col" }
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "flex justify-center mb-4" }
const _hoisted_14 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_15 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_CurrentDetails = _resolveComponent("CurrentDetails")!
  const _component_UpdateDetails = _resolveComponent("UpdateDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_Navbar, { activeTab: 0 })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isQueryCompanyUserLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[7] || (_cache[7] = [
            _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex flex-col gap-4" }, [
            _createElementVNode("span", { class: "text-black text-3xl font-semibold leading-9 text-left" }, "Account Information")
          ], -1)),
          (!_ctx.viewUpdateFields)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    onClick: _ctx.handleUpdate,
                    class: "flex flex-col justify-center items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Update ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (_ctx.updateCompanyUserLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[9] || (_cache[9] = [
                      _createElementVNode("span", { class: "text-primary-purple" }, "Saving...", -1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    onClick: _ctx.handleCancel,
                    class: "flex flex-col justify-center items-center p-3 w-full text-black border border-gray-300 rounded-md text-sm font-medium leading-none text-center"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    onClick: _ctx.handleSave,
                    class: "flex flex-col justify-center items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Save ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]))
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Error Icon",
                    class: "w-4 h-4"
                  })
                ], -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.viewUpdateFields)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_CurrentDetails, {
                firstName: _ctx.companyUserFirstName,
                lastName: _ctx.companyUserLastName,
                email: _ctx.companyUserEmail,
                title: _ctx.companyUserTitle,
                companyName: _ctx.companyName,
                companyWebsite: _ctx.companyWebsite,
                profilePicture: _ctx.companyUserProfilePicture
              }, null, 8, ["firstName", "lastName", "email", "title", "companyName", "companyWebsite", "profilePicture"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_UpdateDetails, {
                firstName: _ctx.companyUserFirstName,
                lastName: _ctx.companyUserLastName,
                email: _ctx.companyUserEmail,
                title: _ctx.companyUserTitle,
                profilePicture: _ctx.companyUserProfilePicture,
                companyName: _ctx.companyName,
                companyWebsite: _ctx.companyWebsite,
                "onUpdate:firstName": _cache[0] || (_cache[0] = ($event: any) => (_ctx.companyUserFirstName = $event)),
                "onUpdate:lastName": _cache[1] || (_cache[1] = ($event: any) => (_ctx.companyUserLastName = $event)),
                "onUpdate:email": _cache[2] || (_cache[2] = ($event: any) => (_ctx.companyUserEmail = $event)),
                "onUpdate:title": _cache[3] || (_cache[3] = ($event: any) => (_ctx.companyUserTitle = $event)),
                "onUpdate:profilePicture": _cache[4] || (_cache[4] = ($event: any) => (_ctx.companyUserProfilePicture = $event)),
                "onUpdate:companyName": _cache[5] || (_cache[5] = ($event: any) => (_ctx.companyName = $event)),
                "onUpdate:companyWebsite": _cache[6] || (_cache[6] = ($event: any) => (_ctx.companyWebsite = $event))
              }, null, 8, ["firstName", "lastName", "email", "title", "profilePicture", "companyName", "companyWebsite"])
            ]))
      ])
    ])
  ]))
}