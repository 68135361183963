import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-2 w-full justify-between p-8 bg-gray-100" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex gap-2 pr-4 border-r border-gray-300" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex justify-center items-center self-stretch px-3 w-12 h-12 bg-purple-100 rounded-md" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "text-gray-900 font-sans text-2xl font-semibold leading-9" }
const _hoisted_9 = { class: "flex gap-2 pl-4 text-left" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "flex justify-center items-center self-stretch px-3 w-12 h-12 bg-purple-100 rounded-md" }
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col text-left" }, [
      _createElementVNode("span", { class: "text-gray-500 font-sans text-lg font-medium leading-6" }, "Return on Experts Spend"),
      _createElementVNode("span", { class: "text-gray-900 font-sans text-2xl font-semibold leading-9" }, "Coming Soon")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              innerHTML: _ctx.EARNINGS_ICON,
              class: "text-primary-purple"
            }, null, 8, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-gray-500 font-sans text-lg font-medium leading-6" }, "Total Spend", -1)),
          _createElementVNode("span", _hoisted_8, "$" + _toDisplayString(_ctx.totalSpend), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              innerHTML: _ctx.BADGE_CHECK_ICON,
              class: "text-primary-purple"
            }, null, 8, _hoisted_12)
          ])
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("span", { class: "text-gray-500 font-sans text-lg font-medium leading-6" }, "Closed Deals"),
          _createElementVNode("span", { class: "text-gray-900 font-sans text-2xl font-semibold leading-9" }, "Coming Soon")
        ], -1))
      ])
    ])
  ]))
}