<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    @click.self="closeModal"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="closeModal"
      >
        ✕
      </button>
      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <!-- Icon Container -->
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <!-- Custom Image Icon -->
            <img
              src="@/assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-1">
          <span
            class="text-gray-800 text-base font-semibold leading-6 text-left"
            >Team Members</span
          >
          <span class="text-left text-gray-500 text-xs font-medium leading-4"
            >Please type the email addresses, and press enter, of the team
            members you wanna invite to your company.</span
          >
        </div>
        <div class="flex flex-col gap-1">
          <BaseLabel
            forAttr="email"
            text="Email Address"
            class="text-sm font-medium leading-5 text-zinc-800 text-left"
          />
          <BaseInput
            id="email"
            v-model="email"
            type="email"
            @keyup.enter="addEmail()"
            @input="handleEmailInput()"
            placeholder="Enter email address"
            :className="{
              'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
              'border-red-500': emailWarning,
              'border-gray-300': !emailWarning,
            }"
          />
          <span
            class="text-xs mt-1 text-gray-400 text-left"
            :class="{ 'text-red-500': emailWarning }"
          >
            {{ emailWarningMessage }}
          </span>
        </div>
        <div class="flex flex-col gap-2 border border-gray-200 p-2 rounded-lg">
          <div class="flex flex-col gap-1">
            <span class="text-gray-700 text-sm font-medium leading-5 text-left"
              >Selected members ({{ emailsList.length }})</span
            >
          </div>
          <div
            class="flex flex-wrap gap-2"
            style="max-height: 80px; overflow-y: auto"
          >
            <div
              v-for="(email, index) in emailsList"
              :key="index"
              class="email-item text-sm font-medium text-gray-700 px-2 items-center bg-gray-100 rounded-full border border-gray-300"
            >
              <span>{{ email }}</span>
              <BaseButton
                type="button"
                @click="removeEmail(index)"
                class="text-lg ml-2 hover:text-red-700 text-gray-700 focus:outline-none"
                >x</BaseButton
              >
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-end gap-2 items-center">
          <BaseButton
            type="button"
            @click="clearEmails()"
            class="bg-gray-200 text-black px-4 py-2 rounded-md text-sm font-medium leading-5"
            >Clear All</BaseButton
          >
          <BaseButton
            type="button"
            class="bg-primary-purple text-white px-4 py-2 rounded-md text-sm font-medium leading-5"
            @click="sendInvites()"
            :disabled="sendInviteLoading"
            ><span v-if="!sendInviteLoading">Send Invite</span
            ><span v-else>Sending.....</span></BaseButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";

const SEND_INVITE_MUTATION = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      successMessage
      errors
    }
  }
`;

export default defineComponent({
  name: "AddTeamMemberModal",
  components: {
    BaseInput,
    BaseLabel,
    BaseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    invitationType: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const email = ref("");
    const emailWarning = ref(false);
    const emailWarningMessage = ref("");
    const emailsList = ref<string[]>([]);
    const errorMessage = ref("");
    const userStore = useUserStore();

    const {
      mutate: sendInvite,
      onDone: sendInviteDone,
      onError: sendInviteError,
      loading: sendInviteLoading,
    } = useMutation(SEND_INVITE_MUTATION);

    const closeModal = () => {
      emit("update:isVisible", false);
    };

    const handleEmailInput = () => {
      emailWarning.value = false;
      emailWarningMessage.value = "";
      clearErrorMessages();
    };

    const validEmail = (email: string) => {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      return pattern.test(email);
    };

    const clearErrorMessages = () => {
      if (errorMessage.value !== "") {
        errorMessage.value = "";
        emailWarning.value = false;
        emailWarningMessage.value = "";
      }
    };

    const clearEmails = () => {
      emailsList.value = [];
    };

    const sendInvites = () => {
      if (emailsList.value.length === 0) {
        emailWarning.value = true;
        emailWarningMessage.value = "Please add at least one email";
      } else {
        // console.log("sendProgramId ", props.sendProgramId);
        sendInvite({
          input: {
            inviteParams: {
              emails: emailsList.value,
              programId:
                props.invitationType === "experts"
                  ? userStore.selectedProgram?.id
                  : null,
            },
            inviteType: props.invitationType,
          },
        });

        sendInviteDone((response) => {
          if (response) {
            clearErrorMessages();
            const data = response.data.createInvitation;
            if (data.errors.length > 0) {
              errorMessage.value = data.errors.join(". ");
              console.log("error 2", errorMessage.value);
            } else {
              handleSentSuccessfully();
            }
          }
        });

        sendInviteError((error: ApolloError) => {
          errorMessage.value = error.message;
          console.log("error 1", error);
        });
      }
    };

    const handleSentSuccessfully = () => {
      emit("update:emailsSent");
      emailsList.value = [];
    };

    const addEmail = () => {
      clearErrorMessages();
      if (!validEmail(email.value)) {
        emailWarning.value = true;
        emailWarningMessage.value = "Invalid email";
      } else if (emailsList.value.includes(email.value)) {
        emailWarning.value = true;
        emailWarningMessage.value = "Email already added";
      } else {
        emailsList.value.push(email.value);
        email.value = "";
      }
    };

    const removeEmail = (index: number) => {
      emailsList.value.splice(index, 1);
    };

    return {
      closeModal,
      email,
      emailWarning,
      emailWarningMessage,
      emailsList,
      handleEmailInput,
      addEmail,
      errorMessage,
      removeEmail,
      sendInviteLoading,
      sendInvites,
      clearEmails,
    };
  },
});
</script>
