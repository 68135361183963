import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start pt-6 rounded-none" }
const _hoisted_2 = { class: "flex flex-col items-start self-stretch mt-0" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "self-stretch my-auto text-left" }
const _hoisted_7 = {
  key: 0,
  class: "z-10 shrink-0 ml-2.5 w-px h-7 bg-violet-300 border border-violet-300 border-solid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(["flex gap-3 items-center self-stretch text-sm font-medium leading-none", {
          'text-white': _ctx.currentStep === index,
          'text-violet-300': _ctx.currentStep !== index,
        }])
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                innerHTML: _ctx.stepIcon(index),
                class: _normalizeClass([_ctx.stepClass(index), "mr-1"])
              }, null, 10, _hoisted_5),
              _createElementVNode("span", _hoisted_6, _toDisplayString(step), 1)
            ]),
            _createElementVNode("div", null, [
              (index < _ctx.steps.length - 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2))
      }), 128))
    ])
  ]))
}