import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/shared/failure.svg'


const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_2 = { class: "overflow-x-auto w-full" }
const _hoisted_3 = { class: "flex justify-center mb-4" }
const _hoisted_4 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_5 = { class: "min-w-full border border-gray-300" }
const _hoisted_6 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_10 = { class: "px-4 py-2 border-b" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_13 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_14 = { class: "px-2 rounded-lg text-gray-800 bg-gray-200" }
const _hoisted_15 = { class: "px-4 py-2 border-b" }
const _hoisted_16 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "Error Icon",
                  class: "w-4 h-4"
                })
              ], -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("table", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("thead", { class: "bg-gray-150 justify-between" }, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " NAME "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " STATUS "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " ACTIONS ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyUsersRows, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row.id,
              class: _normalizeClass(["hover:bg-gray-50 text-left", {
            'opacity-50': row.active === false,
          }])
            }, [
              _createElementVNode("td", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(row.firstName) + " " + _toDisplayString(row.lastName), 1),
                  _createElementVNode("span", null, _toDisplayString(row.email), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_9, [
                _createElementVNode("span", {
                  class: _normalizeClass(["px-2 rounded-lg", {
                'text-green-800 bg-green-100': row.active === true,
                'text-red-800 bg-red-100': row.active === false,
              }])
                }, _toDisplayString(row.active ? "Accepted" : "Disabled"), 3)
              ]),
              _createElementVNode("td", _hoisted_10, [
                (row.email !== _ctx.userStore.email)
                  ? (_openBlock(), _createBlock(_component_BaseButton, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.handleDeleteClick(index, 'companyUser')),
                      disabled: row.active === false,
                      class: "px-4 py-1 text-red-600 text-sm font-medium leading-5 bg-red-50 border border-red-600 rounded-md"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          innerHTML: _ctx.DELETE_ICON,
                          class: "text-red-600"
                        }, null, 8, _hoisted_11)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"]))
                  : _createCommentVNode("", true)
              ])
            ], 2))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invitationsRows, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row.id,
              class: _normalizeClass(["hover:bg-gray-50 text-left", {
            'opacity-50': row.status === 'expired',
          }])
            }, [
              _createElementVNode("td", _hoisted_12, _toDisplayString(row.userEmail), 1),
              _createElementVNode("td", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.capitalizeFirstLetter(row.status)), 1)
              ]),
              _createElementVNode("td", _hoisted_15, [
                _createVNode(_component_BaseButton, {
                  onClick: ($event: any) => (_ctx.handleDeleteClick(index, 'invitation')),
                  disabled: _ctx.isLoading || row.status === 'expired',
                  class: "px-4 py-1 text-red-600 text-sm font-medium leading-5 bg-red-50 border border-red-600 rounded-md"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: _ctx.DELETE_ICON,
                      class: "text-red-600"
                    }, null, 8, _hoisted_16)
                  ]),
                  _: 2
                }, 1032, ["onClick", "disabled"])
              ])
            ], 2))
          }), 128))
        ])
      ])
    ])
  ], 64))
}