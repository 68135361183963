import avatar from "@/assets/shared/avatar-2.svg";
import avatar2 from "@/assets/shared/avatars/avatar-3.svg";
import avatar3 from "@/assets/shared/avatars/avatar-4.svg";
import avatar4 from "@/assets/shared/avatars/avatar-5.svg";
import avatar5 from "@/assets/shared/avatars/avatar-6.svg";
import avatar6 from "@/assets/shared/avatars/avatar-7.svg";
import avatar7 from "@/assets/shared/avatars/avatar-8.svg";
import avatar8 from "@/assets/shared/avatars/avatar-9.svg";
import avatar9 from "@/assets/shared/avatars/avatar-10.svg";

export const directoryProfiles = [
  {
    id: "1",
    uuid: "1",
    firstName: "Courtney",
    lastName: "Henry",
    country: "United States",
    imageUrl: avatar,
    userProgramProfile: [
      {
        summary:
          "I'm an expert in Salesforce, streamlining CRM workflows and customizing solutions to boost sales and customer engagement.",
      },
    ],
  },
  {
    id: "2",
    uuid: "2",
    firstName: "Alex",
    lastName: "Johnson",
    country: "United Kingdom",
    imageUrl: avatar2,
    userProgramProfile: [
      {
        summary:
          "With deep knowledge of Tableau, I design interactive dashboards and generate insights to support data-driven decision-making.",
      },
    ],
  },
  {
    id: "3",
    uuid: "3",
    firstName: "Wendy",
    lastName: "Lee",
    country: "Canada",
    imageUrl: avatar3,
    userProgramProfile: [
      {
        summary:
          "I specialize in Microsoft Power BI, creating dynamic reports and analytics tools that help businesses track and achieve their goals",
      },
    ],
  },
  {
    id: "4",
    uuid: "4",
    firstName: "Michael",
    lastName: "Chang",
    country: "Singapore",
    imageUrl: avatar4,
    userProgramProfile: [
      {
        summary:
          "I have extensive experience with Adobe Photoshop, producing stunning visuals and marketing materials that elevate brand identity.",
      },
    ],
  },
  {
    id: "5",
    uuid: "5",
    firstName: "Emma",
    lastName: "Rodriguez",
    country: "Spain",
    imageUrl: avatar5,
    userProgramProfile: [
      {
        summary:
          "As an Asana power user, I optimize team workflows and project timelines for maximum efficiency.",
      },
    ],
  },
  {
    id: "6",
    uuid: "6",
    firstName: "Sarah",
    lastName: "Henry",
    country: "United States",
    imageUrl: avatar6,
    userProgramProfile: [
      {
        summary:
          "I'm skilled in Shopify, building e-commerce stores that drive conversions and provide seamless customer experiences.",
      },
    ],
  },
  {
    id: "7",
    uuid: "7",
    firstName: "Dave",
    lastName: "Smith",
    country: "United Kingdom",
    imageUrl: avatar7,
    userProgramProfile: [
      {
        summary:
          "With expertise in QuickBooks, I streamline financial management for small businesses, ensuring accurate reporting and compliance.",
      },
    ],
  },
  {
    id: "8",
    uuid: "8",
    firstName: "Sarah",
    lastName: "Lee",
    country: "Canada",
    imageUrl: avatar8,
    userProgramProfile: [
      {
        summary:
          "I'm proficient in Zoom, organizing seamless virtual events and webinars with professional hosting techniques.",
      },
    ],
  },
  {
    id: "9",
    uuid: "9",
    firstName: "Tom",
    lastName: "Chang",
    country: "Singapore",
    imageUrl: avatar9,
    userProgramProfile: [
      {
        summary:
          "As an expert in Canva, I create compelling social media graphics and marketing materials that align with brand identity.",
      },
    ],
  },
];
