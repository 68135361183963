<template>
  <div>My Custom App Where I Will Show the Experts Directory</div>
  <iframe
    src="https://test-manage.slashexperts.com/experts_directory?company_id=6759c713287819d609f21bcd&program=29501845-00f7-49a0-ba1f-4f617a32a158"
    width="100%"
    height="600px"
    frameborder="0"
  >
  </iframe>
</template>
