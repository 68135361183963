<template>
  <div class="flex flex-col gap-2 justify-start items-start">
    <div class="flex flex-col">
      <div>
        <img
          loading="lazy"
          :src="programLogo"
          alt="program logo"
          class="w-[45px] h-[45px] object-cover rounded-full"
        />
      </div>
    </div>
    <div class="flex flex-col">
      <span class="text-gray-900 font-sans text-2xl font-bold leading-7"
        >Meeting with Expert</span
      >
    </div>
    <div class="flex flex-wrap gap-2">
      <div v-html="CLOCK_ICON"></div>
      <span class="text-gray-900/60 font-sans text-base font-bold leading-6"
        >30 min</span
      >
    </div>
    <div class="flex flex-wrap gap-2">
      <div v-html="PHONE_ICON"></div>
      <span class="text-gray-900/60 font-sans text-base font-bold leading-6"
        >Phone call</span
      >
    </div>
    <div class="flex flex-row gap-2" v-if="hasDateAndTime">
      <div v-html="CALENDAR_ICON"></div>
      <span class="text-gray-900/60 font-sans text-base font-bold leading-6">{{
        dateAndTime
      }}</span>
    </div>

    <!-- old booking -->
    <div class="flex flex-col gap-2 mt-4" v-if="previousMeeting">
      <span
        class="text-primary-purple bg-primary-purple/10 px-2 py-1 rounded-md font-sans text-base font-bold leading-6"
        >Previous booking:</span
      >
      <div class="flex flex-row items-center gap-2">
        <div v-html="CALENDAR_ICON"></div>
        <span
          class="text-gray-900/60 font-sans text-base font-bold leading-6"
          >{{ previousMeeting }}</span
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CLOCK_ICON, PHONE_ICON } from "@/assets/svg/shared/svg-constants";
import { COMPANY_LOGO, CALENDAR_ICON } from "@/assets/svg/shared/svg-constants";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const previousMeeting = ref("");
    const programLogo = ref("");

    onMounted(() => {
      checkPreviousMeeting();
      checkProgramLogo();
    });

    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);

      // Define options for the date format
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long", // Full weekday name (e.g., "Monday")
        month: "short", // Short month name (e.g., "Nov")
        day: "numeric", // Day of the month (e.g., "18")
        year: "numeric", // Full year (e.g., "2024")
      };

      return new Intl.DateTimeFormat("en-US", options).format(date);
    };

    const formatTimeRange = (timeString: string): string => {
      // Create a Date object for the starting time (8:00 PM)
      const [hour, minute] = timeString.split(":").map(Number);
      const startTime = new Date();
      startTime.setHours(hour, minute, 0, 0);

      // Calculate the ending time (8:30 PM)
      const endTime = new Date(startTime.getTime() + 30 * 60000); // Add 30 minutes

      // Format both times
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      const startFormatted = new Intl.DateTimeFormat("en-US", options).format(
        startTime
      );
      const endFormatted = new Intl.DateTimeFormat("en-US", options).format(
        endTime
      );

      return `${startFormatted} - ${endFormatted}`;
    };

    const hasDateAndTime = computed(() => {
      // Check for the presence of the query param (example: "userId")
      return route.query.date && route.query.startTime;
    });

    const dateAndTime = computed(() => {
      const date = route.query.date as string;
      const time = route.query.startTime as string;
      return `${formatTimeRange(time)}, ${formatDate(date)} `;
    });

    const checkPreviousMeeting = () => {
      if (route.query.oldBooking) {
        const current = route.query.oldBooking as string;
        const dateObject = new Date(current);

        const formattedDateTime = dateObject.toLocaleString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });

        previousMeeting.value = formattedDateTime;
      }
    };

    const checkProgramLogo = () => {
      if (route.query.logo) {
        let logo = route.query.logo as string;
        if (logo.includes(process.env.VUE_APP_S3_DOMAIN)) {
          programLogo.value = logo;
        } else {
          programLogo.value = fetchLogoFromWebsite(logo);
        }
      }
    };

    const fetchLogoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        console.log("error fetching logo from website", websiteUrl);
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    return {
      CLOCK_ICON,
      PHONE_ICON,
      COMPANY_LOGO,
      CALENDAR_ICON,
      hasDateAndTime,
      dateAndTime,
      previousMeeting,
      programLogo,
    };
  },
});
</script>
