import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AddProgramDetails from "../views/expert-program/AddProgramDetailsView.vue";
import SignUp from "../views/SignUpView.vue";
import AddAccountBalance from "../views/expert-program/AddAccountBalanceView.vue";
import AddDirectory from "../views/expert-program/AddDirectoryView.vue";
import ExpertsDirectory from "../views/expert-program/ExpertsDirectoryView.vue";
import AddWidget from "../views/expert-program/AddWidgetView.vue";
import AddIntegration from "../views/expert-program/AddIntegrationView.vue";
import AddSalesforceIntegration from "../views/expert-program/AddSalesforceIntegrationView.vue";
import InviteExperts from "../views/expert-program/InviteExpertsView.vue";
import BillingPage from "../views/dashboard/BillingView.vue";
import AccountPage from "../views/dashboard/AccountView.vue";
import TeamPage from "../views/dashboard/TeamView.vue";
import ExpertPrograms from "../views/dashboard/ExpertProgramsView.vue";
import AnalyticsPage from "../views/dashboard/AnalyticsView.vue";
import SignInLanding from "../views/SignInLandingView.vue";
import BookMeeting from "../views/expert-meeting/BookMeetingView.vue";
import DirectoryTesting from "../views/DirectoryTestingView.vue";
import { useUserStore } from "@/stores/user";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/invite/company",
    redirect: "/company/signup",
  },
  {
    path: "/company/signup",
    name: "CompanySignUp",
    component: SignUp,
  },
  {
    path: "/company/analytics",
    name: "AnalyticsPage",
    component: AnalyticsPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/expert-program/add-program-details",
    name: "AddProgramDetails",
    component: AddProgramDetails,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/expert-program/add-account-balance",
    name: "AddAccountBalance",
    component: AddAccountBalance,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/expert-program/add-directory",
    name: "AddDirectory",
    component: AddDirectory,
    meta: { requiresAuth: true },
  },
  {
    path: "/experts_directory",
    name: "ExpertsDirectory",
    component: ExpertsDirectory,
  },
  {
    path: "/company/expert-program/add-widget",
    name: "AddWidget",
    component: AddWidget,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/expert-program/add-integration",
    name: "AddIntegration",
    component: AddIntegration,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/integration/salesforce",
    name: "AddSalesforceIntegration",
    component: AddSalesforceIntegration,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/expert-program/invite-experts",
    name: "InviteExperts",
    component: InviteExperts,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/billing-page",
    name: "BillingPage",
    component: BillingPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/account-page",
    name: "AccountPage",
    component: AccountPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/team-page",
    name: "TeamPage",
    component: TeamPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/programs",
    name: "ExpertPrograms",
    component: ExpertPrograms,
    meta: { requiresAuth: true },
  },
  {
    path: "/company/signin/landing",
    name: "SignInLanding",
    component: SignInLanding,
  },
  {
    path: "/company/expert-meeting/book-meeting",
    name: "BookMeeting",
    component: BookMeeting,
  },
  {
    path: "/directory-testing",
    name: "DirectoryTesting",
    component: DirectoryTesting,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, _, next) => {
  const userStore = useUserStore();

  if (to.meta.requiresAuth && !userStore.isLoggedIn) {
    const redirectURL = `${process.env.VUE_APP_SIGN_IN_FORM_URL}?error=Please login again.`;
    window.location.href = redirectURL;
  } else {
    next();
  }
});

export default router;
