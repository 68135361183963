<template>
  <div class="flex flex-col gap-4 border border-gray-200 rounded-lg p-4">
    <!-- available balance text + update button + amount + available -->
    <div class="flex flex-col gap-4">
      <!-- available balance text + update -->
      <div class="flex flex-wrap justify-between">
        <div class="flex items-start gap-6">
          <div
            class="flex flex-col text-left gap-3 border-r border-gray-200 pr-6"
          >
            <span class="text-gray-800 text-base font-semibold leading-6"
              >Available Balance</span
            >
            <span class="text-black text-base font-medium leading-6"
              >${{ availableBalance }}</span
            >
          </div>
          <div class="flex flex-col text-left gap-3">
            <span class="text-gray-800 text-base font-semibold leading-6"
              >Pending Invoice Amount
            </span>
            <span class="text-black text-base font-medium leading-6"
              >${{ pendingBalance }}</span
            >
          </div>
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="updateAccountBalance"
            class="flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center"
          >
            Update
          </BaseButton>
        </div>
      </div>

      <!-- auto top up + payment method -->
      <div class="flex flex-col">
        <div class="flex flex-wrap justify-between gap-2">
          <!-- auto top up balance -->
          <div
            class="flex flex-col text-left gap-2 bg-gray-150 p-4 rounded-lg flex-1"
          >
            <span class="text-gray-800 text-base font-semibold leading-6"
              >Auto Top-up</span
            >
            <span class="text-gray-800 text-base font-semibold leading-6"
              >The amount you want to auto top-up</span
            >
            <span
              v-if="autoTopup"
              class="text-gray-600 text-sm font-normal leading-5"
            >
              If amount goes below
              <span class="font-semibold">${{ lowBalanceAmount }}</span> auto
              top-up for
              <span class="font-semibold">${{ autoRechargeAmount }}</span>
            </span>
            <span v-else class="text-gray-600 text-sm font-normal leading-5"
              >Auto top-up is disabled</span
            >
          </div>

          <!-- payment method details -->
          <div
            class="flex flex-col border border-gray-200 rounded-lg p-4 flex-1"
          >
            <span
              class="text-gray-800 text-base text-left font-semibold leading-6"
              >{{ paymentMethodText() }}</span
            >
            <div class="flex flex-row justify-between">
              <div class="flex flex-col text-left rounded-lg flex-1">
                <div
                  class="flex flex-wrap bg-gray-150 p-4 rounded-lg justify-between"
                >
                  <div class="flex flex-col">
                    <span
                      v-if="paymentMethodSource === 'credit_card'"
                      class="text-gray-800 text-base font-semibold leading-6"
                    >
                      Card ending with *** ****
                      {{ paymentMethodCardNumber.slice(-4) }}
                    </span>
                    <span
                      v-else-if="paymentMethodSource === 'invoice'"
                      class="text-gray-800 text-base font-semibold leading-6"
                    >
                      Invoice email: {{ paymentMethodInvoiceRecipientEmail }}
                    </span>
                    <span class="text-gray-600 text-sm font-normal leading-5"
                      >Date added: {{ paymentMethodCreatedAt }}</span
                    >
                  </div>
                  <div class="flex flex-col">
                    <span
                      class="px-2 rounded-lg bg-primary-purple text-white font-sans text-xs font-normal leading-normal"
                      >DEFAULT</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "AccountBalance",
  components: { BaseButton },
  props: {
    availableBalance: {
      type: Number,
      required: true,
    },
    pendingBalance: {
      type: Number,
      required: true,
    },
    autoTopup: {
      type: Boolean,
      required: true,
    },
    autoRechargeAmount: {
      type: Number,
      required: true,
    },
    lowBalanceAmount: {
      type: Number,
      required: true,
    },
    paymentMethodCardNumber: {
      type: String,
      required: true,
    },
    paymentMethodCreatedAt: {
      type: String,
      required: true,
    },
    updateAccountBalance: {
      type: Function as PropType<() => void>,
      required: true,
    },
    paymentMethodSource: {
      type: String,
      required: true,
    },
    paymentMethodInvoiceRecipientEmail: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const paymentMethodText = () => {
      return props.paymentMethodSource === "credit_card"
        ? "Credit Card"
        : "Invoice";
    };

    return {
      paymentMethodText,
    };
  },
});
</script>
