<template>
  <div
    class="flex items-center border border-gray-300 rounded-md w-full overflow-hidden"
  >
    <span class="pl-3 pr-1 text-gray-500 whitespace-nowrap">
      {{ leadingText }}
    </span>

    <input
      type="text"
      v-model="inputValue"
      :placeholder="placeholder"
      class="flex-1 pl-1 pr-3 py-2 outline-none text-gray-500 min-w-0"
      @blur="formatValue"
    />

    <span class="pr-3 text-gray-500 whitespace-nowrap">
      {{ trailingText }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InputWithText",
  props: {
    leadingText: {
      type: String,
      default: "",
    },
    trailingText: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Enter number",
    },
    modelValue: {
      type: [Number, null],
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref<string>(
      props.modelValue !== null && typeof props.modelValue === "number"
        ? props.modelValue.toFixed(2)
        : "" // Default to an empty string for null or undefined
    );

    const formatValue = () => {
      const formattedValue = inputValue.value
        .toString()
        .replace(/[^0-9.]/g, "");
      const match = formattedValue.match(/^(\d+(\.\d{0,2})?)?/);
      inputValue.value = match ? match[0] : "";
      emitValue();
    };

    const emitValue = () => {
      if (inputValue.value) {
        const value = parseFloat(inputValue.value as string).toFixed(2);
        inputValue.value = value;
        emit("update:modelValue", parseFloat(value));
      } else {
        emit("update:modelValue", null); // Emit null when input is empty
      }
    };

    watch(
      () => props.modelValue,
      (newVal) => {
        inputValue.value =
          newVal !== null && typeof newVal === "number"
            ? newVal.toFixed(2)
            : "";
      }
    );

    return {
      inputValue,
      formatValue,
      emitValue,
    };
  },
});
</script>

<style scoped>
/* Only using Tailwind CSS */
</style>
