import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 p-4" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = { class: "flex gap-2 items-center" }
const _hoisted_4 = { class: "text-gray-800 text-base font-semibold leading-6" }
const _hoisted_5 = {
  key: 0,
  class: "text-green-800 text-base px-2 py-1 bg-green-50 rounded-md font-semibold leading-6"
}
const _hoisted_6 = { class: "overflow-x-auto w-full max-h-[400px] overflow-y-auto" }
const _hoisted_7 = { class: "min-w-full border border-gray-300" }
const _hoisted_8 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_9 = { class: "flex flex-col" }
const _hoisted_10 = { class: "text-gray-500 text-xs font-normal leading-4" }
const _hoisted_11 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_12 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_13 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_14 = { class: "px-4 py-2 border-b" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  colspan: "5",
  class: "py-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoadingSuggestedExperts || _ctx.isSendingInvite)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, " Suggested Experts (" + _toDisplayString(_ctx.suggestedExperts.length) + ") ", 1),
      (_ctx.sentSuccessfully)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, " Invite sent "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("table", _hoisted_7, [
        _cache[3] || (_cache[3] = _createElementVNode("thead", { class: "bg-gray-150" }, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Name "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Title "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Company "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Source "),
            _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4" }, " Action ")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestedExperts, (row, index) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: index,
              class: "hover:bg-gray-50 text-left"
            }, [
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, [
                  _createTextVNode(_toDisplayString(row.fullName) + " ", 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(row.email), 1)
                ])
              ]),
              _createElementVNode("td", _hoisted_11, _toDisplayString(row.title), 1),
              _createElementVNode("td", _hoisted_12, _toDisplayString(row.organizationName), 1),
              _createElementVNode("td", _hoisted_13, _toDisplayString(row.source), 1),
              _createElementVNode("td", _hoisted_14, [
                _createVNode(_component_BaseButton, {
                  onClick: ($event: any) => (_ctx.handleInvite(index)),
                  disabled: _ctx.isSendingInvite,
                  class: "px-4 py-1 bg-indigo-100 text-primary-purple text-sm font-medium leading-5 border border-gray-300 rounded-md"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" Invite ")
                  ])),
                  _: 2
                }, 1032, ["onClick", "disabled"])
              ])
            ]))
          }), 128)),
          (_ctx.hasNextPage)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
                _createElementVNode("td", _hoisted_16, [
                  _createVNode(_component_BaseButton, {
                    onClick: _ctx.loadMoreSuggestions,
                    disabled: _ctx.isSendingInvite,
                    class: "px-6 py-2 rounded-md text-sm bg-primary-purple/10 text-primary-purple"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Load More")
                    ])),
                    _: 1
                  }, 8, ["onClick", "disabled"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}