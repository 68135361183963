<template>
  <div
    class="flex-1 gap-4 p-4 border border-solid border-gray-300 bg-white rounded-lg"
  >
    <div class="flex flex-col pb-3">
      <span
        class="text-gray-800 font-sans text-lg font-semibold leading-6 tracking-tight"
        >Contact Field Mapping</span
      >
    </div>
    <div class="flex flex-col gap-4">
      <!-- First Name -->
      <div class="flex flex-wrap gap-2 justify-between items-center">
        <span class="text-gray-600 font-sans text-sm font-medium leading-5"
          >First name</span
        >
        <select
          v-model="localFirstName"
          @change="updateFirstName"
          class="border rounded p-2 w-32 md:w-64"
        >
          <option value="" disabled>Select</option>
          <option
            v-for="option in contactMappingFields"
            :key="option.label"
            :value="option.name"
          >
            {{ option.label }}
          </option>
        </select>
      </div>

      <!-- Last Name -->
      <div class="flex flex-wrap gap-2 justify-between items-center">
        <span class="text-gray-600 font-sans text-sm font-medium leading-5"
          >Last name</span
        >
        <select
          v-model="localLastName"
          @change="updateLastName"
          class="border rounded p-2 w-32 md:w-64"
        >
          <option value="" disabled>Select</option>
          <option
            v-for="option in contactMappingFields"
            :key="option.label"
            :value="option.name"
          >
            {{ option.label }}
          </option>
        </select>
      </div>

      <!-- Title -->
      <div class="flex flex-wrap gap-2 justify-between items-center">
        <span class="text-gray-600 font-sans text-sm font-medium leading-5"
          >Title</span
        >
        <select
          v-model="localTitle"
          @change="updateTitle"
          class="border rounded p-2 w-32 md:w-64"
        >
          <option value="" disabled>Select</option>
          <option
            v-for="option in contactMappingFields"
            :key="option.label"
            :value="option.name"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";

export default defineComponent({
  name: "ContactFieldMapping",
  props: {
    contactFirstName: {
      type: String,
      required: true,
    },
    contactLastName: {
      type: String,
      required: true,
    },
    contactTitle: {
      type: String,
      required: true,
    },
    contactMappingFields: {
      type: Array as PropType<{ label: string; name: string }[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Create local state for the props
    const localFirstName = ref(props.contactFirstName);
    const localLastName = ref(props.contactLastName);
    const localTitle = ref(props.contactTitle);

    // Emit updated values back to parent on change
    const updateFirstName = () => {
      emit("update:contactFirstName", localFirstName.value);
    };

    const updateLastName = () => {
      emit("update:contactLastName", localLastName.value);
    };

    const updateTitle = () => {
      emit("update:contactTitle", localTitle.value);
    };

    watch(
      () => props.contactFirstName,
      (newVal) => {
        localFirstName.value = newVal;
      }
    );

    watch(
      () => props.contactLastName,
      (newVal) => {
        localLastName.value = newVal;
      }
    );

    watch(
      () => props.contactTitle,
      (newVal) => {
        localTitle.value = newVal;
      }
    );

    return {
      localFirstName,
      localLastName,
      localTitle,
      updateFirstName,
      updateLastName,
      updateTitle,
    };
  },
});
</script>
