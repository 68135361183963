import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative" }
const _hoisted_2 = { class: "flex justify-center mb-4" }
const _hoisted_3 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_4 = { class: "flex flex-col gap-3" }
const _hoisted_5 = { class: "flex flex-col gap-1" }
const _hoisted_6 = { class: "flex flex-col gap-2 border border-gray-200 p-2 rounded-lg" }
const _hoisted_7 = { class: "flex flex-col gap-1" }
const _hoisted_8 = { class: "text-gray-700 text-sm font-medium leading-5 text-left" }
const _hoisted_9 = {
  class: "flex flex-wrap gap-2",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_10 = { class: "flex flex-wrap justify-end gap-2 items-center" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50",
        onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "absolute top-2 right-2 text-gray-500 hover:text-gray-700",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, " ✕ "),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: "Error Icon",
                      class: "w-4 h-4"
                    })
                  ], -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex flex-col gap-1" }, [
              _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6 text-left" }, "Team Members"),
              _createElementVNode("span", { class: "text-left text-gray-500 text-xs font-medium leading-4" }, "Please type the email addresses, and press enter, of the team members you wanna invite to your company.")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_BaseLabel, {
                forAttr: "email",
                text: "Email Address",
                class: "text-sm font-medium leading-5 text-zinc-800 text-left"
              }),
              _createVNode(_component_BaseInput, {
                id: "email",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.addEmail()), ["enter"])),
                onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleEmailInput())),
                placeholder: "Enter email address",
                className: {
              'flex-grow px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
              'border-red-500': _ctx.emailWarning,
              'border-gray-300': !_ctx.emailWarning,
            }
              }, null, 8, ["modelValue", "className"]),
              _createElementVNode("span", {
                class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.emailWarning }])
              }, _toDisplayString(_ctx.emailWarningMessage), 3)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, "Selected members (" + _toDisplayString(_ctx.emailsList.length) + ")", 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailsList, (email, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "email-item text-sm font-medium text-gray-700 px-2 items-center bg-gray-100 rounded-full border border-gray-300"
                  }, [
                    _createElementVNode("span", null, _toDisplayString(email), 1),
                    _createVNode(_component_BaseButton, {
                      type: "button",
                      onClick: ($event: any) => (_ctx.removeEmail(index)),
                      class: "text-lg ml-2 hover:text-red-700 text-gray-700 focus:outline-none"
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode("x")
                      ])),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_BaseButton, {
                type: "button",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clearEmails())),
                class: "bg-gray-200 text-black px-4 py-2 rounded-md text-sm font-medium leading-5"
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode("Clear All")
                ])),
                _: 1
              }),
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "bg-primary-purple text-white px-4 py-2 rounded-md text-sm font-medium leading-5",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.sendInvites())),
                disabled: _ctx.sendInviteLoading
              }, {
                default: _withCtx(() => [
                  (!_ctx.sendInviteLoading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Send Invite"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, "Sending....."))
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}