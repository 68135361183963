<template>
  <div class="flex justify-center mb-4">
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
  <div class="flex flex-wrap min-h-screen">
    <div
      class="flex flex-col w-[80%] items-start pt-4 border-t border-gray-200 gap-4"
    >
      <div class="flex flex-col text-left gap-2">
        <span class="text-black text-sm font-medium leading-5">
          Integration
        </span>
        <span class="text-gray-500 text-sm font-normal leading-6">
          Integrate your SlashExperts program with CRM. It only takes 5 minutes
          and no admin access required. It's easy!
        </span>
      </div>

      <!-- integration cards -->
      <div class="flex flex-wrap gap-4">
        <HubspotCard />
        <SalesforceCard
          :salesforceSynced="salesforceSynced"
          :loading="salesforceLoading"
          :connectSalesforce="connectSalesforce"
        />
      </div>
    </div>
    <div class="flex flex-col w-[20%] border-l border-t border-gray-200">
      <div class="flex flex-col">
        <DetailsSection />
      </div>
    </div>
  </div>
  <SyncSalesforceModal
    :isVisible="isSyncSalesforceModalVisible"
    :providerAccountName="salesforceName"
    :providerAccountEmail="salesforceEmail"
    :programId="userStore.selectedProgram?.id || ''"
    @close="closeModal"
    @updatedSettings="salesforceMappingUpdated"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import HubspotCard from "./HubspotCard.vue";
import SalesforceCard from "./SalesforceCard.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";
import SyncSalesforceModal from "./SyncSalesforceModal.vue";
import DetailsSection from "./DetailsSection.vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

const GET_PROVIDER_ACCOUNT = gql`
  query getProviderAccount($programId: ID!) {
    getProviderAccount(programId: $programId) {
      name
      email
    }
  }
`;

const GET_SALESFORCE_OAUTH_URL = gql`
  query getSalesforceOauthUrl($programId: ID!, $redirectTo: String!) {
    salesforceoauth(programId: $programId, redirectTo: $redirectTo) {
      oauthUrl
    }
  }
`;

export default defineComponent({
  name: "ProgramDetails",
  components: {
    HubspotCard,
    SalesforceCard,
    SyncSalesforceModal,
    DetailsSection,
  },
  setup() {
    const isSyncSalesforceModalVisible = ref(false);
    const salesforceSynced = ref(false);
    const salesforceEmail = ref("");
    const salesforceName = ref("");
    const errorMessage = ref("");
    const salesforceLoading = ref(false);
    const userStore = useUserStore();
    const route = useRoute();
    const router = useRouter();
    const isLoadingSalesforceOauthUrl = ref(false);
    const isLoadingProviderAccount = ref(false);

    onMounted(() => {
      checkSalesForceSyncError();
      getProviderAccountData();
    });

    const getProviderAccountData = () => {
      isLoadingProviderAccount.value = true;
      const {
        result: providerAccountQueryResult,
        error: providerAccountQueryError,
        refetch: refetchProviderAccount,
      } = useQuery(GET_PROVIDER_ACCOUNT, {
        programId: userStore.selectedProgram?.id,
      });

      refetchProviderAccount();

      watch(
        () => providerAccountQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (newValue?.getProviderAccount?.email) {
              salesforceSynced.value = true;
              checkSalesForceSync();
              salesforceEmail.value = newValue.getProviderAccount.email;
              salesforceName.value = newValue.getProviderAccount.name;
            }
            isLoadingProviderAccount.value = false;
          }
        },
        { immediate: true }
      );

      watch(
        () => providerAccountQueryError.value,
        (newError) => {
          if (newError) {
            console.log("providerAccountQueryError ", newError);
            handleErrorMessage(newError.message);
            isLoadingProviderAccount.value = false;
          }
        },
        { immediate: true }
      );
    };

    const checkSalesForceSync = () => {
      if (
        route.query.salesforce_sync &&
        route.query.salesforce_sync === "true"
      ) {
        isSyncSalesforceModalVisible.value = true;
        router.replace({ query: {} });
      }
    };

    const checkSalesForceSyncError = () => {
      console.log("checkSalesForceSyncError", route.query.salesforce_sync);
      if (
        route.query.salesforce_sync &&
        route.query.salesforce_sync === "false"
      ) {
        console.log("checkSalesForceSyncError", route.query.details);
        handleErrorMessage(route.query.details as string);
        router.replace({ query: {} });
      }
    };

    const handleErrorMessage = (message: string) => {
      console.log("handleErrorMessage", message);
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const closeModal = () => {
      isSyncSalesforceModalVisible.value = false;
    };

    const salesforceMappingUpdated = () => {
      getProviderAccountData();
      isSyncSalesforceModalVisible.value = false;
    };

    const connectSalesforce = () => {
      console.log("connectSalesforce", salesforceSynced.value);
      errorMessage.value = "";

      if (salesforceSynced.value) {
        isSyncSalesforceModalVisible.value = true;
        return;
      }

      const {
        result: queryResult,
        error: queryError,
        refetch: refetchSalesforceOauthUrl,
      } = useQuery(GET_SALESFORCE_OAUTH_URL, {
        programId: userStore.selectedProgram?.id,
        redirectTo: "dashboard",
      });

      refetchSalesforceOauthUrl({
        programId: userStore.selectedProgram?.id,
        redirectTo: "dashboard",
      });

      isLoadingSalesforceOauthUrl.value = true;

      watch(
        () => queryResult.value,
        (newValue) => {
          if (!newValue?.salesforceoauth?.oauthUrl) {
            errorMessage.value =
              "Failed to fetch Salesforce authorization URL. Please refresh the page and try again.";
          } else {
            window.location.href = newValue?.salesforceoauth?.oauthUrl;
          }
          isLoadingSalesforceOauthUrl.value = false;
        }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value =
              "Failed to fetch Salesforce authorization URL. Please refresh the page and try again.";
            isLoadingSalesforceOauthUrl.value = false;
          }
        }
      );
    };

    const isLoading = computed(() => {
      return (
        isLoadingSalesforceOauthUrl.value || isLoadingProviderAccount.value
      );
    });

    return {
      salesforceSynced,
      connectSalesforce,
      salesforceLoading,
      isSyncSalesforceModalVisible,
      salesforceName,
      salesforceEmail,
      userStore,
      salesforceMappingUpdated,
      closeModal,
      errorMessage,
      isLoading,
    };
  },
});
</script>
