import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-4 pl-4 pr-4 pb-4 w-full lg:pr-20 lg:w-[40vw] max-w-[500px]" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-900 font-sans text-xl font-bold leading-7 text-left"
}
const _hoisted_4 = {
  key: 1,
  class: "p-2.5 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg text-left"
}
const _hoisted_5 = { class: "flex flex-col text-left" }
const _hoisted_6 = { class: "flex flex-col text-left" }
const _hoisted_7 = { class: "flex flex-col text-left" }
const _hoisted_8 = { class: "flex flex-col text-left" }
const _hoisted_9 = { class: "flex flex-col text-left" }
const _hoisted_10 = { class: "flex flex-col" }
const _hoisted_11 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.attendeeDetailsError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Enter Details "))
        : _createCommentVNode("", true),
      (_ctx.attendeeDetailsError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Failed to fetch attendee details, please refresh to try again. "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BaseLabel, {
        forAttr: "name",
        text: "Name",
        class: "text-gray-800 text-sm font-medium leading-5 font-inter"
      }),
      _createVNode(_component_BaseInput, {
        id: "name",
        modelValue: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
        type: "text",
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleNameInput())),
        placeholder: "",
        className: {
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': _ctx.nameWarning,
          'border-gray-300': !_ctx.nameWarning,
        }
      }, null, 8, ["modelValue", "className"]),
      _createElementVNode("span", {
        class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.nameWarning }])
      }, _toDisplayString(_ctx.nameWarningMessage), 3)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_BaseLabel, {
        forAttr: "title",
        text: "Title",
        class: "text-gray-800 text-sm font-medium leading-5 font-inter"
      }),
      _createVNode(_component_BaseInput, {
        id: "title",
        modelValue: _ctx.title,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.title) = $event)),
        type: "text",
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleTitleInput())),
        placeholder: "",
        className: {
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': _ctx.titleWarning,
          'border-gray-300': !_ctx.titleWarning,
        }
      }, null, 8, ["modelValue", "className"]),
      _createElementVNode("span", {
        class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.titleWarning }])
      }, _toDisplayString(_ctx.titleWarningMessage), 3)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_BaseLabel, {
        forAttr: "company",
        text: "Company",
        class: "text-gray-800 text-sm font-medium leading-5 font-inter"
      }),
      _createVNode(_component_BaseInput, {
        id: "company",
        modelValue: _ctx.company,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.company) = $event)),
        type: "text",
        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleCompanyInput())),
        placeholder: "",
        className: {
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': _ctx.companyWarning,
          'border-gray-300': !_ctx.companyWarning,
        }
      }, null, 8, ["modelValue", "className"]),
      _createElementVNode("span", {
        class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.companyWarning }])
      }, _toDisplayString(_ctx.companyWarningMessage), 3)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_BaseLabel, {
        forAttr: "email",
        text: "Email",
        class: "text-gray-800 text-sm font-medium leading-5 font-inter text-left"
      }),
      _createVNode(_component_BaseInput, {
        id: "email",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email) = $event)),
        type: "email",
        onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.handleEmailInput())),
        placeholder: "Enter email address",
        className: {
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': _ctx.emailWarning,
          'border-gray-300': !_ctx.emailWarning,
        }
      }, null, 8, ["modelValue", "className"]),
      _createElementVNode("span", {
        class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.emailWarning }])
      }, _toDisplayString(_ctx.emailWarningMessage), 3)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_BaseLabel, {
        forAttr: "linkedinURL",
        text: "Linkedin URL",
        class: "text-gray-800 text-sm font-medium leading-5 font-inter text-left"
      }),
      _createVNode(_component_BaseInput, {
        id: "linkedinUrl",
        modelValue: _ctx.linkedinUrl,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.linkedinUrl) = $event)),
        type: "text",
        onInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.handleLinkedinUrlInput())),
        placeholder: "https://www.linkedin.com/in/johndoe_32",
        className: {
          'flex-grow px-3.5 py-2.5 mt-1 text-gray-800 text-sm font-medium leading-5 font-inter whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
          'border-red-500': _ctx.linkedinUrlWarning,
          'border-gray-300': !_ctx.linkedinUrlWarning,
        }
      }, null, 8, ["modelValue", "className"]),
      _createElementVNode("span", {
        class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.linkedinUrlWarning }])
      }, _toDisplayString(_ctx.linkedinUrlWarningMessage), 3)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_BaseLabel, {
        forAttr: "note",
        text: "Please share anything that will help prepare for our meeting.",
        class: "text-gray-800 text-sm font-medium leading-5 font-inter text-left"
      }),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.note) = $event)),
        rows: "4",
        placeholder: "",
        onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleNoteInput($event))),
        class: _normalizeClass(["w-full border rounded px-2 py-1 focus:outline-none", {
          'border-red-500': _ctx.noteWarning,
          'border-gray-300': !_ctx.noteWarning,
        }])
      }, null, 34), [
        [_vModelText, _ctx.note]
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(["text-xs mt-1 text-gray-400 text-left", { 'text-red-500': _ctx.noteWarning }])
      }, _toDisplayString(_ctx.note.length) + " / 512 ", 3)
    ]),
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex flex-col text-left" }, [
      _createElementVNode("span", { class: "font-sans text-gray-500 text-xs font-normal leading-4" }, [
        _createTextVNode("By proceeding you confirm that you have read and agree to "),
        _createElementVNode("a", {
          href: "https://www.slashexperts.com/terms",
          target: "_blank",
          rel: "noopener noreferrer",
          class: "text-primary-purple"
        }, " terms to use "),
        _createTextVNode(" and "),
        _createElementVNode("a", {
          href: "https://www.slashexperts.com/privacy",
          target: "_blank",
          rel: "noopener noreferrer",
          class: "text-primary-purple"
        }, " privacy policy. ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_BaseButton, {
        type: "button",
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.handleScheduleMeeting())),
        class: "max-w-max px-4 py-2 text-xs sm:text-sm font-medium leading-none text-white border border-gray-300 rounded-md bg-primary-purple"
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode(" Schedule Event ")
        ])),
        _: 1
      })
    ])
  ]))
}