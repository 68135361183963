import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-wrap w-full p-6 gap-4" }
const _hoisted_3 = { class: "text-gray-900 font-inter text-lg font-medium leading-6 text-left break-words" }
const _hoisted_4 = { class: "flex flex-col gap-3" }
const _hoisted_5 = { class: "text-gray-500 font-inter text-sm font-normal leading-5 text-left break-words" }
const _hoisted_6 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col w-full p-6" }, [
      _createElementVNode("span", { class: "text-black text-3xl font-semibold leading-9 text-left" }, "Programs")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyPrograms, (program, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: program.id,
          class: "flex flex-col flex-1 justify-between gap-4 rounded-lg border border-gray-300 p-4 h-48 max-w-[220px]"
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.truncateText(program.name, 25)), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.truncateText(program.description || "", 40)), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_BaseButton, {
                type: "button",
                onClick: ($event: any) => (_ctx.handleViewProgram(index)),
                class: "flex flex-col justify-center items-center p-3 w-full bg-violet-100 text-primary-purple rounded-md text-sm font-medium leading-none text-center"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" View Detail ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}