<!-- ModalComponent.vue -->
<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div
      v-if="isLoadingData"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div
      id="sync-salesforce-modal"
      class="bg-white p-6 rounded-lg shadow-lg w-full sm:w-full md:w-4/5 lg:w-1/2 overflow-x-auto max-h-[80vh] overflow-y-auto"
    >
      <div class="flex flex-wrap justify-between text-left py-3">
        <span class="text-sm leading-5 font-medium text-neutral-800"
          >Sync'd Salesforce Account</span
        >
        <BaseButton
          type="button"
          class="text-lg leading-5 font-medium text-neutral-800"
          :disabled="createSalesforceMappingAndSettingLoading"
          @click="closeModal"
          >X</BaseButton
        >
      </div>
      <!-- provider account details -->
      <div
        class="flex flex-col border border-solid max-w-max border-primary-purple bg-white rounded-lg p-0.5"
      >
        <div
          class="flex flex-col border border-solid border-gray-300 rounded-lg p-4"
        >
          <div class="flex flex-wrap gap-3">
            <div class="flex flex-col">
              <div
                class="flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full"
              >
                {{ initials }}
              </div>
            </div>
            <div class="flex flex-col text-left">
              <span class="text-sm leading-5 font-medium text-gray-900">{{
                providerAccountName
              }}</span>
              <span class="text-sm leading-5 font-normal text-gray-500">{{
                providerAccountEmail
              }}</span>
            </div>
            <div v-html="HOLLOW_CHECK_CIRCLE" class="text-primary-purple"></div>
          </div>
        </div>
      </div>

      <!-- Error div -->
      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <!-- Icon Container -->
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <!-- Custom Image Icon -->
            <img
              src="../../../../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>

      <!-- mapping fields (lead and contact) -->
      <div
        class="flex flex-col text-left gap-4 py-4 max-w-[60%] md:max-w-[70%] lg:max-w-[80%]"
      >
        <!-- salesforce fields mapping -->
        <div>
          <LeadFieldMapping
            :leadMappingFields="leadMappingFields"
            :leadFirstName="salesforceData.leadFirstName.value.value"
            :leadLastName="salesforceData.leadLastName.value.value"
            :leadCompany="salesforceData.leadCompany.value.value"
            :leadTitle="salesforceData.leadTitle.value.value"
            @update:leadFirstName="
              salesforceData.leadFirstName.value.value = $event
            "
            @update:leadLastName="
              salesforceData.leadLastName.value.value = $event
            "
            @update:leadCompany="
              salesforceData.leadCompany.value.value = $event
            "
            @update:leadTitle="salesforceData.leadTitle.value.value = $event"
          />
        </div>

        <!-- lead or contact fields mapping -->
        <div>
          <ContactFieldMapping
            :contactMappingFields="contactMappingFields"
            :contactFirstName="salesforceData.contactFirstName.value.value"
            :contactLastName="salesforceData.contactLastName.value.value"
            :contactTitle="salesforceData.contactTitle.value.value"
            @update:contactFirstName="
              salesforceData.contactFirstName.value.value = $event
            "
            @update:contactLastName="
              salesforceData.contactLastName.value.value = $event
            "
            @update:contactTitle="
              salesforceData.contactTitle.value.value = $event
            "
          />
        </div>

        <!-- create new activity toggle -->
        <div>
          <CreateNewActivityToggle
            :createNewActivity="createActivity"
            @update:createNewActivity="createActivity = $event"
          />
        </div>

        <!-- create new lead or contact toggle -->
        <div>
          <CreateNewLeadOrContactToggle
            :createNewLeadOrContact="createLeadOrContact"
            @update:createNewLeadOrContact="createLeadOrContact = $event"
          />
        </div>
      </div>
      <div class="flex justify-end gap-3">
        <BaseButton
          type="button"
          @click="closeModal"
          :disabled="createSalesforceMappingAndSettingLoading"
          class="text-black border border-gray-300 px-4 py-2 rounded"
        >
          Close
        </BaseButton>
        <BaseButton
          type="button"
          @click="handleUpdate"
          :disabled="createSalesforceMappingAndSettingLoading"
          class="bg-primary-purple text-white px-4 py-2 rounded"
        >
          <span v-if="!createSalesforceMappingAndSettingLoading">Update</span>
          <span v-else>Updating...</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { computed, defineComponent, ref, watch } from "vue";
import { HOLLOW_CHECK_CIRCLE } from "@/assets/svg/shared/svg-constants";
import ContactFieldMapping from "@/components/expert-program/integration/ContactFieldMapping.vue";
import LeadFieldMapping from "@/components/expert-program/integration/LeadFieldMapping.vue";
import CreateNewActivityToggle from "@/components/expert-program/integration/CreateNewActivityToggle.vue";
import CreateNewLeadOrContactToggle from "@/components/expert-program/integration/CreateNewLeadOrContactToggle.vue";
import BaseButton from "@/components/shared/BaseButton.vue";

interface MappingField {
  name: string;
  label: string;
}

interface MappingInput {
  id: string;
  salesforceField: string;
  salesforceObject: string;
  mappedField: string;
}

interface SalesforceRecord {
  id: string;
  mappedField: string;
  salesforceField: "first_name" | "last_name" | "company" | "title"; // limiting to specific fields
  salesforceObject: "Lead" | "Contact"; // limiting to specific objects
  value: string; // the value to assign
}

const GET_SALESFORCE_MAPPING_FIELDS = gql`
  query getSalesforceMappingFields($objectType: String!, $programId: ID!) {
    salesforceMappingFields(objectType: $objectType, programId: $programId) {
      name
      label
    }
  }
`;

const CREATE_SALESFORCE_MAPPING_AND_SETTING = gql`
  mutation createSalesforceMappingAndSetting(
    $input: CreateSalesforceMappingAndSettingInput!
  ) {
    createSalesforceMappingAndSetting(input: $input) {
      salesforceSetting {
        createActivity
        createLeadOrContact
      }
      errors
    }
  }
`;

const GET_SALESFORCE_PROGRAM_MAPPING_FIELDS = gql`
  query salesforceProgramMappingFields($programId: ID!) {
    salesforceProgramMappingFields(programId: $programId) {
      id
      salesforceField
      salesforceObject
      mappedField
    }
  }
`;

const GET_SALESFORCE_PROGRAM_SETTINGS = gql`
  query salesforceProgramSetting($programId: ID!) {
    salesforceProgramSetting(programId: $programId) {
      id
      createActivity
      createLeadOrContact
    }
  }
`;

export default defineComponent({
  name: "ModalComponent",
  components: {
    BaseButton,
    LeadFieldMapping,
    ContactFieldMapping,
    CreateNewActivityToggle,
    CreateNewLeadOrContactToggle,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    providerAccountName: {
      type: String,
      required: true,
    },
    providerAccountEmail: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
  },
  emits: ["close", "updatedSettings"],
  setup(props, { emit }) {
    const isLoadingLeadMappingFields = ref(false);
    const isLoadingContactMappingFields = ref(false);
    const isLoadingSalesforceProgramSettings = ref(false);
    const leadMappingFields = ref<MappingField[]>([]);
    const contactMappingFields = ref<MappingField[]>([]);
    const loadingCurrentSetting = ref(false);
    const errorMessage = ref("");
    const createActivity = ref(false);
    const createLeadOrContact = ref(false);
    const salesforceData = {
      contactFirstName: {
        id: "",
        value: ref(""),
      },
      contactLastName: {
        id: "",
        value: ref(""),
      },
      contactTitle: {
        id: "",
        value: ref(""),
      },
      leadFirstName: {
        id: "",
        value: ref(""),
      },
      leadLastName: {
        id: "",
        value: ref(""),
      },
      leadCompany: {
        id: "",
        value: ref(""),
      },
      leadTitle: {
        id: "",
        value: ref(""),
      },
    };

    const isModalVisible = computed(() => props.isVisible);

    watch(
      isModalVisible,
      (newVal) => {
        if (newVal) {
          getSalesforceMappingFields("Lead");
          getSalesforceMappingFields("Contact");
          getSalesforceProgramMappingFields();
          getSalesforceProgramSettings();
        }
      },
      { immediate: true }
    );

    const {
      mutate: createSalesforceMappingAndSetting,
      onDone: createSalesforceMappingAndSettingDone,
      onError: createSalesforceMappingAndSettingError,
      loading: createSalesforceMappingAndSettingLoading,
    } = useMutation(CREATE_SALESFORCE_MAPPING_AND_SETTING);

    const getSalesforceProgramMappingFields = () => {
      loadingCurrentSetting.value = true;

      const {
        result: salesforceProgramMappingFieldsQueryResult,
        error: salesforceProgramMappingFieldsQueryError,
        refetch: refetchSalesforceProgramMappingFields,
      } = useQuery(GET_SALESFORCE_PROGRAM_MAPPING_FIELDS, {
        programId: props.programId,
      });

      refetchSalesforceProgramMappingFields();

      watch(
        () => salesforceProgramMappingFieldsQueryResult.value,
        (newValue) => {
          if (newValue) {
            populateSalesforceData(newValue.salesforceProgramMappingFields);
          }
        },
        { immediate: true }
      );

      watch(
        () => salesforceProgramMappingFieldsQueryError.value,
        (newError) => {
          if (newError) {
            handleErrorMessage(newError.message);
            scrollToTop();
          }
        },
        { immediate: true }
      );
    };

    const getSalesforceProgramSettings = () => {
      isLoadingSalesforceProgramSettings.value = true;

      const {
        result: salesforceProgramSettingsQueryResult,
        error: salesforceProgramSettingsQueryError,
        refetch: refetchSalesforceProgramSettings,
      } = useQuery(GET_SALESFORCE_PROGRAM_SETTINGS, {
        programId: props.programId,
      });

      refetchSalesforceProgramSettings();

      watch(
        () => salesforceProgramSettingsQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (newValue.salesforceProgramSetting) {
              createActivity.value =
                newValue.salesforceProgramSetting.createActivity;
              createLeadOrContact.value =
                newValue.salesforceProgramSetting.createLeadOrContact;
              isLoadingSalesforceProgramSettings.value = false;
            } else {
              isLoadingSalesforceProgramSettings.value = false;
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => salesforceProgramSettingsQueryError.value,
        (newError) => {
          if (newError) {
            handleErrorMessage(newError.message);
            scrollToTop();
          }
        },
        { immediate: true }
      );
    };

    const getSalesforceMappingFields = (objectType: string) => {
      const {
        result: mappingFieldsQueryResult,
        error: mappingFieldsQueryError,
        refetch: refetchMappingFields,
      } = useQuery(GET_SALESFORCE_MAPPING_FIELDS, {
        objectType: objectType,
        programId: props.programId,
      });

      refetchMappingFields();

      watch(
        () => mappingFieldsQueryResult.value,
        (newValue) => {
          if (newValue) {
            if (objectType === "Lead") {
              isLoadingLeadMappingFields.value = false;
            } else {
              isLoadingContactMappingFields.value = false;
            }
            if (newValue?.salesforceMappingFields.length > 0) {
              handleMappingFields(newValue.salesforceMappingFields, objectType);
            }
          }
        },
        { immediate: true }
      );

      watch(
        () => mappingFieldsQueryError.value,
        (newError) => {
          if (newError) {
            handleErrorMessage(newError.message);
            scrollToTop();
          }
        },
        { immediate: true }
      );
    };

    const populateSalesforceData = (records: SalesforceRecord[]) => {
      records.forEach((record) => {
        const { salesforceField, salesforceObject, mappedField, id } = record;
        if (salesforceObject === "Lead") {
          switch (salesforceField) {
            case "first_name":
              salesforceData.leadFirstName.value.value = mappedField;
              salesforceData.leadFirstName.id = id;
              break;
            case "last_name":
              salesforceData.leadLastName.value.value = mappedField;
              salesforceData.leadLastName.id = id;
              break;
            case "company":
              salesforceData.leadCompany.value.value = mappedField;
              salesforceData.leadCompany.id = id;
              break;
            case "title":
              salesforceData.leadTitle.value.value = mappedField;
              salesforceData.leadTitle.id = id;
              break;
          }
        } else if (salesforceObject === "Contact") {
          switch (salesforceField) {
            case "first_name":
              salesforceData.contactFirstName.value.value = mappedField;
              salesforceData.contactFirstName.id = id;
              break;
            case "last_name":
              salesforceData.contactLastName.value.value = mappedField;
              salesforceData.contactLastName.id = id;
              break;
            case "title":
              salesforceData.contactTitle.value.value = mappedField;
              salesforceData.contactTitle.id = id;
              break;
          }
        }
      });
      loadingCurrentSetting.value = false;
    };

    const handleMappingFields = (
      mappingFields: MappingField[],
      objectType: string
    ) => {
      if (objectType === "Lead") {
        leadMappingFields.value = mappingFields;
        isLoadingLeadMappingFields.value = false;
      } else {
        contactMappingFields.value = mappingFields;
        isLoadingContactMappingFields.value = false;
      }
    };

    const validateSalesforceData = (): boolean => {
      return Object.values(salesforceData).some(
        (field) => field.value.value.trim() === ""
      );
    };

    const payload = (): MappingInput[] => {
      const types = ["Lead", "Contact"];
      const fields = ["first_name", "last_name", "company", "title"];
      const mappingInput: MappingInput[] = [];

      for (const type of types) {
        for (const field of fields) {
          const key = `${type.toLowerCase()}${capitalize(
            field
          )}` as keyof typeof salesforceData;
          const dataObject = salesforceData[key];

          if (dataObject && dataObject.value.value !== undefined) {
            const fieldObject: Partial<MappingInput> = {
              salesforceField: field,
              salesforceObject: type,
              mappedField: dataObject.value.value,
            };

            if (dataObject.id) {
              fieldObject.id = dataObject.id;
            }

            mappingInput.push(fieldObject as MappingInput);
          }
        }
      }

      return mappingInput;
    };

    // Helper function to capitalize the first letter of a string, e.g., "first_name" -> "FirstName"
    const capitalize = (field: string): string => {
      const parts = field.split("_");
      return parts
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join("");
    };

    const handleCreateSalesforceMappingAndSetting = () => {
      const params = payload();
      createSalesforceMappingAndSetting({
        input: {
          params: params,
          createActivity: createActivity.value,
          createLeadOrContact: createLeadOrContact.value,
          programId: props.programId,
        },
      });

      createSalesforceMappingAndSettingDone((response) => {
        if (response) {
          const data = response.data.createSalesforceMappingAndSetting;
          if (data.errors.length > 0) {
            handleErrorMessage(data.errors.join(", "));
            scrollToTop();
          } else {
            emit("updatedSettings");
          }
        }
      });

      createSalesforceMappingAndSettingError((error: ApolloError) => {
        handleErrorMessage(error.message);
        scrollToTop();
      });
    };

    const handleUpdate = () => {
      if (!validateSalesforceData()) {
        handleCreateSalesforceMappingAndSetting();
      } else {
        handleErrorMessage("Please select all fields.");
        scrollToTop();
        // scrollToTop();
      }
    };

    const handleErrorMessage = (message: string) => {
      isLoadingLeadMappingFields.value = false;
      isLoadingContactMappingFields.value = false;
      isLoadingSalesforceProgramSettings.value = false;
      loadingCurrentSetting.value = false;
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 2500);
    };

    const scrollToTop = () => {
      const element = document.getElementById("sync-salesforce-modal"); // Directly target the modal by ID
      if (!element) return; // Exit if the modal is not found

      const scrollTop = element.scrollTop;
      if (scrollTop > 0) {
        window.requestAnimationFrame(scrollToTop); // Recursively call until at the top
        element.scrollTop = scrollTop - scrollTop / 8;
      }
    };

    const closeModal = () => {
      emit("close");
    };

    const isLoadingData = computed(() => {
      return (
        isLoadingLeadMappingFields.value ||
        isLoadingContactMappingFields.value ||
        isLoadingSalesforceProgramSettings.value ||
        loadingCurrentSetting.value
      );
    });

    const initials = computed(() => {
      if (props.providerAccountName) {
        const words = props.providerAccountName.trim().split(" ");
        return words.map((word) => word[0].toUpperCase()).join("");
      }
      return "";
    });

    return {
      closeModal,
      isLoadingData,
      isLoadingLeadMappingFields,
      isLoadingContactMappingFields,
      leadMappingFields,
      contactMappingFields,
      createActivity,
      createLeadOrContact,
      initials,
      HOLLOW_CHECK_CIRCLE,
      salesforceData,
      handleUpdate,
      errorMessage,
      createSalesforceMappingAndSettingLoading,
    };
  },
});
</script>
