import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "bg-white min-h-screen flex flex-col" }
const _hoisted_2 = { class: "flex flex-grow max-md:flex-col" }
const _hoisted_3 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_4 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_5 = { class: "flex flex-col w-4/5 justify-center mt-10 pt-6 pb-1 max-md:w-full" }
const _hoisted_6 = { class: "flex justify-center mb-4" }
const _hoisted_7 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_8 = { class: "flex flex-col w-1/3 mx-auto justify-center max-md:mt-4 max-md:w-full" }
const _hoisted_9 = { id: "expertProgramForm" }
const _hoisted_10 = { class: "relative mx-auto" }
const _hoisted_11 = { class: "relative z-20 flex gap-5 justify-between mt-8 text-left" }
const _hoisted_12 = { class: "flex flex-col flex-grow basis-1/2" }
const _hoisted_13 = { class: "relative mt-3 text-left z-10" }
const _hoisted_14 = ["rows"]
const _hoisted_15 = { class: "flex flex-col mt-4" }
const _hoisted_16 = { class: "relative mt-4 text-left z-10" }
const _hoisted_17 = { class: "flex flex-col 2xl:flex-row gap-2" }
const _hoisted_18 = { class: "w-full 2xl:w-3/5" }
const _hoisted_19 = { class: "w-full 2xl:w-2/5" }
const _hoisted_20 = {
  id: "fee",
  class: "px-3.5 py-2.5 text-sm leading-5 text-gray-500 whitespace-nowrap bg-gray-100 rounded-md shadow-sm w-full block"
}
const _hoisted_21 = { class: "text-xs text-gray-500" }
const _hoisted_22 = { class: "relative flex flex-col mt-6 text-zinc-800 gap-2" }
const _hoisted_23 = { class: "flex flex-col justify-center" }
const _hoisted_24 = { class: "relative flex flex-col mt-6 text-zinc-800" }
const _hoisted_25 = { class: "relative" }
const _hoisted_26 = { class: "relative flex flex-col justify-start mt-6 text-base font-medium leading-6 z-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!
  const _component_SoftwareSearch = _resolveComponent("SoftwareSearch")!
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_GoalsCheckboxList = _resolveComponent("GoalsCheckboxList")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("aside", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
            _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
              _createTextVNode(" Get Started "),
              _createElementVNode("br")
            ])
          ], -1)),
          _createVNode(_component_StepsList, { currentStep: 0 })
        ])
      ]),
      _createElementVNode("section", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Error Icon",
                    class: "w-4 h-4"
                  })
                ], -1)),
                _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[16] || (_cache[16] = _createElementVNode("h1", { class: "text-3xl font-semibold leading-9 text-left text-zinc-800" }, " Expert Program Details ", -1)),
          _cache[17] || (_cache[17] = _createElementVNode("p", { class: "mt-3 text-base leading-6 text-left text-neutral-700" }, " Create your account to get started ", -1)),
          _createElementVNode("form", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_BaseLabel, {
                    forAttr: "Software",
                    text: "Software",
                    class: "text-sm font-medium leading-5 text-zinc-800"
                  }),
                  _cache[14] || (_cache[14] = _createElementVNode("span", { class: "text-xs" }, " Add your software ", -1)),
                  _createVNode(_component_SoftwareSearch, { onSelectProgram: _ctx.selectProgram }, null, 8, ["onSelectProgram"])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[15] || (_cache[15] = _createElementVNode("label", {
                  for: "about",
                  class: "self-start mb-2 mt-4 text-sm font-medium leading-none text-gray-700"
                }, " About The Program ", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  ref: "aboutTextarea",
                  id: "about",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.about) = $event)),
                  rows: _ctx.rows,
                  maxlength: "1057",
                  onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAboutInput && _ctx.handleAboutInput(...args))),
                  required: "",
                  placeholder: "",
                  class: _normalizeClass(["resize-none w-full p-2 text-base text-gray-500 bg-white border rounded-md shadow-sm focus:outline-none", {
                    'border-red-500': _ctx.aboutError,
                    'border-gray-300': !_ctx.aboutError,
                  }])
                }, null, 42, _hoisted_14), [
                  [_vModelText, _ctx.about]
                ]),
                _createElementVNode("span", {
                  class: _normalizeClass(["mt-2 text-sm leading-none ml-3 px-1 text-left max-md:max-w-full", {
                    'text-red-500': _ctx.aboutError,
                    'text-gray-500': !_ctx.aboutError,
                  }])
                }, _toDisplayString(_ctx.about.length) + "/1056", 3)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "settings",
                  text: "Settings",
                  class: "text-lg font-medium leading-5 text-zinc-800 text-left"
                })
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "payout",
                      text: "Expert Payout per Meeting",
                      class: "text-sm font-medium leading-5 text-zinc-800"
                    }),
                    _createElementVNode("div", null, [
                      _createVNode(_component_CurrencyInput, {
                        modelValue: _ctx.payout,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payout) = $event)),
                        leadingText: "$",
                        trailingText: "USD",
                        placeholder: "100"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_BaseLabel, {
                      forAttr: "fee",
                      text: "SlashExperts Fee (35%)",
                      class: "text-sm font-medium leading-5 text-zinc-800"
                    }),
                    _createElementVNode("span", _hoisted_20, " $ " + _toDisplayString(_ctx.payout * 0.35), 1)
                  ])
                ]),
                _createElementVNode("span", _hoisted_21, "Total Cost Per Meeting: $" + _toDisplayString(_ctx.payout + _ctx.payout * 0.35), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "meetingDuration",
                  text: "Payout only if meeting duration is more than 5 minutes.",
                  class: "text-sm font-medium leading-5 text-zinc-800 text-left"
                }),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    class: _normalizeClass(["flex items-center w-12 h-6 rounded-xl transition-colors duration-300", {
                      'bg-violet-600': _ctx.shortMeetingDuration,
                      'bg-gray-200': !_ctx.shortMeetingDuration,
                    }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleShortMeetingDuration())),
                    "aria-label": "Toggle button"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: _normalizeClass(["flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform", {
                        'translate-x-6': _ctx.shortMeetingDuration,
                        'translate-x-0.5': !_ctx.shortMeetingDuration,
                      }])
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "linkedinURL",
                  text: "Your Company Linkedin URL",
                  class: "text-sm font-medium leading-5 text-zinc-800 text-left"
                }),
                _createElementVNode("div", _hoisted_25, [
                  _withDirectives(_createElementVNode("input", {
                    id: "linkedinUrl",
                    type: "text",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.linkedinUrl) = $event)),
                    placeholder: "https://www.linkedin.com/company/companyname",
                    class: "px-3.5 py-2.5 mt-1 w-full bg-white rounded-md shadow-sm pr-1 border border-gray-300 border-solid",
                    required: ""
                  }, null, 512), [
                    [_vModelText, _ctx.linkedinUrl]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createVNode(_component_BaseLabel, {
                  forAttr: "Goals",
                  text: "Goals & Use Cases",
                  class: "text-lg font-medium leading-5 text-zinc-800 text-left"
                }),
                _createVNode(_component_GoalsCheckboxList, {
                  convertWebsiteVisitors: _ctx.convertWebsiteVisitors,
                  "onUpdate:convertWebsiteVisitors": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.convertWebsiteVisitors) = $event)),
                  generateIntentSignals: _ctx.generateIntentSignals,
                  "onUpdate:generateIntentSignals": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.generateIntentSignals) = $event)),
                  enableSales: _ctx.enableSales,
                  "onUpdate:enableSales": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.enableSales) = $event)),
                  engageNewCustomer: _ctx.engageNewCustomer,
                  "onUpdate:engageNewCustomer": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.engageNewCustomer) = $event)),
                  driveConversion: _ctx.driveConversion,
                  "onUpdate:driveConversion": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.driveConversion) = $event)),
                  sdrSequenceStep: _ctx.sdrSequenceStep,
                  "onUpdate:sdrSequenceStep": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.sdrSequenceStep) = $event)),
                  lateStageCustomerReferences: 
                    _ctx.lateStageCustomerReferences
                  ,
                  "onUpdate:lateStageCustomerReferences": _cache[11] || (_cache[11] = ($event: any) => ((
                    _ctx.lateStageCustomerReferences
                  ) = $event))
                }, null, 8, ["convertWebsiteVisitors", "generateIntentSignals", "enableSales", "engageNewCustomer", "driveConversion", "sdrSequenceStep", "lateStageCustomerReferences"])
              ])
            ])
          ])
        ]),
        _createVNode(_component_FooterSection, {
          isDisabled: _ctx.isDisabled,
          onNext: _ctx.goNext
        }, null, 8, ["isDisabled", "onNext"])
      ])
    ])
  ]))
}