<template>
  <div class="flex flex-col gap-4 p-4">
    <div
      v-if="isLoadingSuggestedExperts || isSendingInvite"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <div class="flex gap-2 items-center">
      <span class="text-gray-800 text-base font-semibold leading-6">
        Suggested Experts ({{ suggestedExperts.length }})
      </span>
      <span
        v-if="sentSuccessfully"
        class="text-green-800 text-base px-2 py-1 bg-green-50 rounded-md font-semibold leading-6"
      >
        Invite sent
      </span>
    </div>

    <div class="overflow-x-auto w-full max-h-[400px] overflow-y-auto">
      <table class="min-w-full border border-gray-300">
        <thead class="bg-gray-150">
          <tr>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Name
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Title
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Company
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Source
            </th>
            <th
              class="px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider uppercase leading-4"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in suggestedExperts"
            :key="index"
            class="hover:bg-gray-50 text-left"
          >
            <td
              class="px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b"
            >
              <span class="flex flex-col">
                {{ row.fullName }}
                <span class="text-gray-500 text-xs font-normal leading-4">
                  {{ row.email }}
                </span>
              </span>
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              {{ row.title }}
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              {{ row.organizationName }}
            </td>
            <td
              class="px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b"
            >
              {{ row.source }}
            </td>
            <td class="px-4 py-2 border-b">
              <BaseButton
                @click="handleInvite(index)"
                :disabled="isSendingInvite"
                class="px-4 py-1 bg-indigo-100 text-primary-purple text-sm font-medium leading-5 border border-gray-300 rounded-md"
              >
                Invite
              </BaseButton>
            </td>
          </tr>
          <tr v-if="hasNextPage">
            <td colspan="5" class="py-4">
              <BaseButton
                @click="loadMoreSuggestions"
                :disabled="isSendingInvite"
                class="px-6 py-2 rounded-md text-sm bg-primary-purple/10 text-primary-purple"
                >Load More</BaseButton
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { defineComponent, onMounted, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { SuggestedExpert, SuggestedExpertsEdge } from "@/types/expert-types";

const LIST_SUGGESTED_EXPERTS_QUERY = gql`
  query getSuggestedExperts($programId: ID!, $first: Int!) {
    getSuggestedExperts(programId: $programId, first: $first) {
      edges {
        node {
          organizationName
          title
          email
          fullName
          source
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const SEND_INVITE_MUTATION = gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      successMessage
      errors
    }
  }
`;

export default defineComponent({
  name: "SuggestedExperts",
  components: {
    BaseButton,
  },
  props: {
    programId: {
      type: String,
      required: false,
    },
    handleError: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const suggestedExperts = ref<SuggestedExpert[]>([]);
    const hasNextPage = ref<boolean>(false);
    const count = ref<number>(1);
    const isLoadingSuggestedExperts = ref<boolean>(false);
    const errorMessage = ref<string>("");
    const sentSuccessfully = ref<boolean>(false);
    const isSendingInvite = ref<boolean>(false);
    const activeButtonIndex = ref<number>(0);

    const {
      mutate: sendInvite,
      onDone: sendInviteDone,
      onError: sendInviteError,
      // loading: sendInviteLoading,
    } = useMutation(SEND_INVITE_MUTATION);

    const fetchSuggestedExperts = () => {
      isLoadingSuggestedExperts.value = true;
      const { onResult, onError } = useQuery(
        LIST_SUGGESTED_EXPERTS_QUERY,
        {
          programId: props.programId,
          first: count,
        },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        if (result) {
          // suggestedExperts.value = result.data.getSuggestedExperts;
          // console.log("result ", result.data.getSuggestedExperts.edges);
          suggestedExperts.value = result.data.getSuggestedExperts.edges.map(
            (edge: SuggestedExpertsEdge) => edge.node
          );
          hasNextPage.value =
            result.data.getSuggestedExperts.pageInfo.hasNextPage;
          isLoadingSuggestedExperts.value = false;
        }
      });

      onError((error) => {
        if (error) {
          console.error(error);
          isLoadingSuggestedExperts.value = false;
          props.handleError?.(error.message);
        }
      });
    };

    const callSendInvite = (
      suggestedExpert: SuggestedExpert,
      index: number
    ) => {
      if (!suggestedExpert.email) {
        props.handleError?.("Suggested expert has no email.");
        return;
      }
      isSendingInvite.value = true;
      activeButtonIndex.value = index;

      sendInvite({
        input: {
          inviteParams: {
            programId: props.programId,
            note: "",
            emails: [suggestedExpert.email],
          },
          inviteType: "experts",
        },
      });

      sendInviteDone((response) => {
        if (response) {
          // clearErrorMessages();
          const data = response.data.createInvitation;
          if (data.errors.length > 0) {
            props.handleError?.(data.errors.join(". "));
          } else {
            handleSentSuccessfully();
          }
          isSendingInvite.value = false;
        }
      });

      sendInviteError((error: ApolloError) => {
        if (error) {
          props.handleError?.(error.message);
          isSendingInvite.value = false;
        }
      });
    };

    const handleInvite = (index: number) => {
      callSendInvite(suggestedExperts.value[index], index);
    };

    const handleSentSuccessfully = () => {
      suggestedExperts.value = suggestedExperts.value.filter(
        (expert) =>
          expert.email !== suggestedExperts.value[activeButtonIndex.value].email
      );
      sentSuccessfully.value = true;
      setTimeout(() => {
        sentSuccessfully.value = false;
      }, 3000);
    };

    const loadMoreSuggestions = () => {
      count.value += 10;
      fetchSuggestedExperts();
    };

    onMounted(() => {
      if (props.programId) {
        fetchSuggestedExperts();
      }
    });

    return {
      suggestedExperts,
      hasNextPage,
      handleInvite,
      isLoadingSuggestedExperts,
      isSendingInvite,
      loadMoreSuggestions,
      sentSuccessfully,
      activeButtonIndex,
      errorMessage,
    };
  },
});
</script>
