<template>
  <div class="flex flex-col min-h-screen">
    <header class="flex flex-col w-full border-b">
      <Navbar :activeTab="1" />
    </header>
    <div v-if="!userStore.selectedProgram" class="flex flex-col">
      <ProgramsList @programSelected="handleProgramSelected" />
    </div>
    <div v-else class="flex flex-col">
      <main class="flex flex-col w-full pt-12 pl-6 gap-4">
        <div class="flex flex-col w-full">
          <span class="text-black text-3xl font-semibold leading-9 text-left">{{
            userStore.selectedProgram?.name
          }}</span>
        </div>
        <div class="flex flex-col w-full">
          <div class="flex flex-wrap gap-2 items-start gap-6">
            <div
              class="flex flex-col"
              :class="{
                'border-b border-primary-purple text-primary-purple':
                  activeTab === 0,
                'text-gray-500': activeTab !== 0,
              }"
              @click="setActiveTab(0)"
            >
              <span class="text-sm font-medium leading-5"
                >Expert Program Details</span
              >
            </div>
            <div
              class="flex flex-col"
              :class="{
                'border-b border-primary-purple text-primary-purple':
                  activeTab === 1,
                'text-gray-500': activeTab !== 1,
              }"
              @click="setActiveTab(1)"
            >
              <span class="text-sm font-medium leading-5"
                >Directory & Widgets</span
              >
            </div>
            <div
              class="flex flex-col"
              :class="{
                'border-b border-primary-purple text-primary-purple':
                  activeTab === 2,
                'text-gray-500': activeTab !== 2,
              }"
              @click="setActiveTab(2)"
            >
              <span class="text-sm font-medium leading-5">Experts</span>
            </div>
          </div>
        </div>

        <div v-if="activeTab === 0">
          <ProgramDetailsContainer />
        </div>
        <div v-if="activeTab === 1">
          <DirectoryAndWidgetContainer />
        </div>
        <div v-if="activeTab === 2">
          <ExpertsContainer />
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Navbar from "@/components/dashboard/Navbar.vue";
import DirectoryAndWidgetContainer from "@/components/dashboard/programs/directory-and-widget/PageContainer.vue";
import ProgramDetailsContainer from "@/components/dashboard/programs/program-details/PageContainer.vue";
import ExpertsContainer from "@/components/dashboard/programs/experts/PageContainer.vue";
import { useUserStore } from "@/stores/user";
import ProgramsList from "@/components/dashboard/programs/ProgramsList.vue";
import { Program } from "@/types/dashboard-types";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "BillingPage",
  components: {
    Navbar,
    DirectoryAndWidgetContainer,
    ProgramDetailsContainer,
    ProgramsList,
    ExpertsContainer,
  },
  setup() {
    const activeTab = ref(0);
    const userStore = useUserStore();
    // const programSelected = ref(false);
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      if (route.query.salesforce_sync && userStore.selectedProgram) {
        setActiveTab(0);
      } else {
        if (route.query.tab) {
          setActiveTab(parseInt(route.query.tab as string));
        }
      }
    });

    const setActiveTab = (tab: number) => {
      router.replace({
        path: route.path,
        query: {
          ...route.query,
          tab: tab,
        },
      });

      activeTab.value = tab;
    };

    const handleProgramSelected = (program: Program) => {
      if (program) {
        userStore.setSelectedProgram(program);
        setActiveTab(0);
        // programSelected.value = true;
      }
    };

    // watch(
    //   () => userStore.selectedProgram,
    //   (newValue) => {
    //     if (!newValue) {
    //       programSelected.value = false;
    //     }
    //   },
    //   { immediate: true }
    // );

    return {
      activeTab,
      // programSelected,
      handleProgramSelected,
      userStore,
      setActiveTab,
    };
  },
});
</script>
