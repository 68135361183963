import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '../../../../assets/shared/bullseye-icon.svg'


const _hoisted_1 = { class: "flex flex-col p-4 gap-4" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { class: "flex flex-wrap justify-between items-center" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "flex flex-row gap-2" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "flex flex-wrap gap-2 items-center text-left" }
const _hoisted_9 = { class: "text-lg font-light leading-loose text-violet-600" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "mt-3 mr-6 text-sm leading-5 text-gray-500 text-left max-md:mr-2.5" }
const _hoisted_12 = { class: "flex flex-col gap-4" }
const _hoisted_13 = { class: "flex flex-col text-left gap-2" }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 2,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = {
  key: 3,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_21 = ["innerHTML"]
const _hoisted_22 = {
  key: 4,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 5,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = {
  key: 6,
  class: "flex flex-row gap-2 items-center"
}
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 0,
  class: "flex flex-col"
}
const _hoisted_29 = { class: "flex flex-col mt-4" }
const _hoisted_30 = { class: "flex flex-wrap gap-2 items-center text-left" }
const _hoisted_31 = { class: "text-lg font-light leading-loose text-violet-600" }
const _hoisted_32 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_UpdateProgramDetailsModal = _resolveComponent("UpdateProgramDetailsModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex flex-col text-left" }, [
            _createElementVNode("span", { class: "text-black text-sm font-medium leading-5" }, "Details")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "gap-2 text-primary-purple font-inter text-base font-medium leading-6",
              onClick: _ctx.openEditDetails
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", { innerHTML: _ctx.EDIT_ICON }, null, 8, _hoisted_6),
                  _cache[0] || (_cache[0] = _createTextVNode(" Edit "))
                ])
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, [
            _createElementVNode("div", { innerHTML: _ctx.CIRCLE_EXCLAMATION }, null, 8, _hoisted_10)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "my-auto text-sm font-medium leading-none text-zinc-800" }, " About Program ", -1))
        ]),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.aboutProgram), 1)
      ]),
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "flex flex-col" }, [
        _createElementVNode("hr", { class: "shrink-0 mt-6 max-w-full h-px border border-solid" })
      ], -1)),
      _createElementVNode("div", _hoisted_12, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex flex-wrap gap-2 items-center text-left" }, [
          _createElementVNode("img", {
            loading: "lazy",
            src: _imports_0,
            alt: "Bullseye icon",
            class: "shrink-0 my-auto w-5 aspect-square"
          }),
          _createElementVNode("h2", { class: "my-auto text-sm font-medium leading-none text-zinc-800" }, " Goals $ Use Cases ")
        ], -1)),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.convertWebsiteVisitors)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_15),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " Convert Website Visitors ", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.generateIntentSignals)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_17),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " Generate Intent Signals ", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.enableSales)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_19),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " Enable Sales ", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.driveConversion)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_21),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " Drive Conversion ", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.lateStageCustomerReferences)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_23),
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " Late Stage Customer References ", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.sdrSequenceStep)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_25),
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " SDR Sequence Step ", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.engageNewCustomer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                _createElementVNode("div", {
                  innerHTML: _ctx.CHECK_CIRCLE,
                  class: "text-primary-purple"
                }, null, 8, _hoisted_27),
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-gray-800 font-inter text-sm font-normal" }, " Engage New Customer ", -1))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.payoutOnlyCallDurationMoreThan5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex flex-col" }, [
              _createElementVNode("hr", { class: "shrink-0 mt-6 max-w-full h-px border border-solid" })
            ], -1)),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("span", _hoisted_31, [
                  _createElementVNode("div", { innerHTML: _ctx.CIRCLE_EXCLAMATION }, null, 8, _hoisted_32)
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("h2", { class: "my-auto text-sm font-medium leading-none text-zinc-800" }, " Settings ", -1))
              ]),
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "mt-3 mr-6 text-sm leading-5 text-gray-500 text-left max-md:mr-2.5" }, " Meeting/call must be more than 5 minutes long to qualify for the payout. ", -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.editDetailsVisible)
      ? (_openBlock(), _createBlock(_component_UpdateProgramDetailsModal, {
          key: 0,
          programId: _ctx.userStore.selectedProgram?.id || '',
          isVisible: _ctx.editDetailsVisible,
          aboutProgram: _ctx.aboutProgram,
          payoutOnlyCallDurationMoreThan5: _ctx.payoutOnlyCallDurationMoreThan5,
          convertWebsiteVisitors: _ctx.convertWebsiteVisitors,
          generateIntentSignals: _ctx.generateIntentSignals,
          enableSales: _ctx.enableSales,
          engageNewCustomer: _ctx.engageNewCustomer,
          driveConversion: _ctx.driveConversion,
          sdrSequenceStep: _ctx.sdrSequenceStep,
          lateStageCustomerReferences: _ctx.lateStageCustomerReferences,
          onClose: _ctx.closeEditDetails,
          onProgramUpdated: _ctx.programUpdated
        }, null, 8, ["programId", "isVisible", "aboutProgram", "payoutOnlyCallDurationMoreThan5", "convertWebsiteVisitors", "generateIntentSignals", "enableSales", "engageNewCustomer", "driveConversion", "sdrSequenceStep", "lateStageCustomerReferences", "onClose", "onProgramUpdated"]))
      : _createCommentVNode("", true)
  ], 64))
}