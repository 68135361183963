import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_2 = { class: "min-w-full border border-gray-300 mb-8" }
const _hoisted_3 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "px-4 py-2 font-medium text-xs leading-4 border-b" }
const _hoisted_10 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b" }
const _hoisted_11 = { class: "flex justify-center relative" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
}
const _hoisted_14 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_15 = { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }
const _hoisted_16 = { class: "px-4 py-2 text-gray-500 text-sm font-normal leading-5 border-b relative flex justify-center" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  class: "absolute left-0 top-full w-32 bg-white rounded shadow-lg border border-gray-200 z-20"
}
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("table", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("thead", { class: "justify-between" }, [
        _createElementVNode("tr", { class: "border-b border-gray-300" }, [
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Name "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Email "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Total Payouts "),
          _createElementVNode("th", { class: "px-4 py-2 text-left text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Status "),
          _createElementVNode("th", { class: "px-4 py-2 text-center text-gray-500 text-xs font-medium tracking-wider leading-4" }, " Actions ")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expertsRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass(["hover:bg-gray-50 text-left", {
          'opacity-50':
            row.active === false ||
            row.joinProgramRequest?.[0]?.status === 'rejected',
        }])
          }, [
            _createElementVNode("td", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(row.firstName) + " " + _toDisplayString(row.lastName), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(row.email), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, "$" + _toDisplayString(row.credits), 1)
              ])
            ]),
            _createElementVNode("td", _hoisted_9, [
              _createElementVNode("span", {
                class: _normalizeClass(["px-2 rounded-lg", {
              'text-green-800 bg-green-100':
                row.joinProgramRequest?.[0]?.status === 'approved',
              'text-red-800 bg-red-100':
                row.joinProgramRequest?.[0]?.status === 'rejected' ||
                row.active === false,
              'text-gray-800 bg-gray-100':
                row.joinProgramRequest?.[0]?.status === 'pending',
              'text-yellow-800 bg-yellow-100':
                _ctx.status(row.joinProgramRequest?.[0]?.status, row.active) ===
                'Pending Submission',
            }])
              }, _toDisplayString(_ctx.status(row.joinProgramRequest?.[0]?.status, row.active)), 3)
            ]),
            _createElementVNode("td", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_BaseButton, {
                  type: "button",
                  class: "dropdown-menu",
                  style: {"width":"auto"},
                  disabled: 
                _ctx.deleteExpertLoading ||
                row.active === false ||
                row.joinProgramRequest?.[0]?.status === 'rejected'
              ,
                  onClick: ($event: any) => (_ctx.toggleDropdown(row.id))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      innerHTML: _ctx.DOTS_VERTICAL_ICON,
                      class: "ml-3 text-gray-800"
                    }, null, 8, _hoisted_12)
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                (_ctx.isDropdownOpen === row.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_BaseButton, {
                        type: "button",
                        onClick: ($event: any) => (_ctx.handleDelete(row.id, 'expert')),
                        class: "block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" Delete ")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ], 2))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invitationsRows, (row) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass([{ 'opacity-50': row.status === 'expired' }, "hover:bg-gray-50 text-left"])
          }, [
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }, " -- ", -1)),
            _createElementVNode("td", _hoisted_14, _toDisplayString(row.userEmail), 1),
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "px-4 py-2 text-gray-900 text-sm font-medium leading-5 border-b" }, " -- ", -1)),
            _createElementVNode("td", _hoisted_15, [
              _createElementVNode("span", {
                class: _normalizeClass(["px-2 rounded-lg", {
              'text-green-800 bg-green-100': row.status === 'accepted',
              'text-red-800 bg-red-100': row.status === 'expired',
              'text-gray-600 bg-gray-200': row.status === 'pending',
            }])
              }, _toDisplayString(_ctx.invitationStatus(row.status)), 3)
            ]),
            _createElementVNode("td", _hoisted_16, [
              _createVNode(_component_BaseButton, {
                type: "button",
                class: "flex justify-center items-center dropdown-menu",
                style: {"width":"auto"},
                disabled: 
              _ctx.expireInvitationLoading ||
              row.status === 'expired' ||
              row.status === 'accepted'
            ,
                onClick: ($event: any) => (_ctx.toggleDropdown(row.id))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    innerHTML: _ctx.DOTS_VERTICAL_ICON,
                    class: "ml-3 text-gray-800"
                  }, null, 8, _hoisted_17)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              (_ctx.isDropdownOpen === row.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("button", {
                      onClick: ($event: any) => (_ctx.handleDelete(row.id, 'invitation')),
                      class: "block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    }, " Delete ", 8, _hoisted_19)
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2))
        }), 128))
      ])
    ])
  ], 64))
}