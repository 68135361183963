import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-wrap gap-5 justify-between px-7 py-3 w-full max-md:px-5" }
const _hoisted_2 = { class: "flex flex-wrap gap-10 text-sm font-medium leading-none text-gray-600" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex flex-wrap gap-5 overflow-x-auto whitespace-nowrap" }
const _hoisted_6 = { class: "flex overflow-hidden items-center py-2 pr-3 pl-2 rounded-md" }
const _hoisted_7 = { class: "flex gap-3 items-center self-stretch my-auto" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md" }
const _hoisted_10 = { class: "flex gap-3 items-center self-stretch my-auto" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "flex flex-col items-center bg-gray-200 rounded-lg px-2 py-1" }
const _hoisted_13 = { class: "flex gap-4 self-start mt-1 relative" }
const _hoisted_14 = { class: "flex z-10 gap-3 items-center mr-0 font-medium" }
const _hoisted_15 = {
  key: 0,
  class: "relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_18 = { class: "flex items-center justify-center w-8 h-8 bg-primary-purple text-white rounded-full" }
const _hoisted_19 = { class: "flex flex-col self-stretch my-auto" }
const _hoisted_20 = { class: "text-sm leading-none text-gray-700" }
const _hoisted_21 = { class: "text-xs leading-none text-gray-500" }
const _hoisted_22 = { class: "absolute right-0 mt-8 bg-white border border-gray-200 rounded-md shadow-lg p-3 w-36" }
const _hoisted_23 = { class: "flex flex-col gap-2" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_NavbarAvailableBalance = _resolveComponent("NavbarAvailableBalance")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", { innerHTML: _ctx.COMPANY_LOGO }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "flex gap-2",
              onClick: _ctx.navigateToPrograms
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.EXPERTISE_ICON,
                  class: _normalizeClass({ 'text-primary-purple': _ctx.activeTab == 1 })
                }, null, 10, _hoisted_8),
                _createElementVNode("span", {
                  class: _normalizeClass(["self-stretch my-auto", { 'text-primary-purple': _ctx.activeTab == 1 }])
                }, "My Programs", 2)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_BaseButton, {
              type: "button",
              class: "flex gap-2",
              onClick: _ctx.navigateToAnalytics
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  innerHTML: _ctx.DASHBOARD_ICON,
                  class: _normalizeClass({ 'text-primary-purple': _ctx.activeTab == 2 })
                }, null, 10, _hoisted_11),
                _createElementVNode("span", {
                  class: _normalizeClass(["self-stretch my-auto", { 'text-primary-purple': _ctx.activeTab == 2 }])
                }, "Analytics", 2)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_NavbarAvailableBalance)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        class: "flex",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
        id: "userProfile"
      }, [
        _createElementVNode("div", _hoisted_14, [
          (_ctx.userStore.profilePicture)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("img", {
                  loading: "lazy",
                  src: _ctx.profilePictureUrl,
                  alt: "User profile picture",
                  class: "w-full h-full object-cover"
                }, null, 8, _hoisted_16),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute inset-0" }, null, -1))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.initials()), 1)
              ])),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.userStore.firstName) + " " + _toDisplayString(_ctx.userStore.lastName), 1),
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.userStore.email), 1)
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "self-start text-xs font-black leading-none text-violet-600" }, null, -1))
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createVNode(_component_BaseButton, {
            type: "button",
            disabled: _ctx.disableLogout,
            class: "w-full text-left flex items-center gap-2",
            onClick: _ctx.goToBilling
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.DOCUMENT_DOLLAR_ICON,
                class: "text-gray-500"
              }, null, 8, _hoisted_24),
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-6" }, "Billing ", -1))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            type: "button",
            disabled: _ctx.disableLogout,
            class: "w-full text-left flex items-center gap-2",
            onClick: _ctx.goToAccount
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.PERSON_ICON,
                class: "text-gray-500"
              }, null, 8, _hoisted_25),
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-6" }, "Account ", -1))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            type: "button",
            disabled: _ctx.disableLogout,
            class: "w-full text-left flex items-center gap-2",
            onClick: _ctx.goToTeam
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.PEOPLE_ICON,
                class: "text-gray-500"
              }, null, 8, _hoisted_26),
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-6" }, "Team ", -1))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            type: "button",
            disabled: _ctx.disableLogout,
            class: "w-full text-left flex items-center gap-2 logout-btn",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: _ctx.LOGOUT_ICON,
                class: "text-red-600 ml-1"
              }, null, 8, _hoisted_27),
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-gray-500 font-sans text-sm font-medium leading-6" }, "Logout ", -1))
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ], 512), [
        [_vShow, _ctx.showDropdown]
      ])
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Error Icon",
              class: "w-4 h-4"
            })
          ], -1)),
          _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage.slice(0, 60)), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}