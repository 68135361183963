<template>
  <div class="overflow-x-auto w-full pr-4">
    <div class="flex flex-col mb-4">
      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <!-- Icon Container -->
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <!-- Custom Image Icon -->
            <img
              src="../../../../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <div class="flex flex-row gap-2">
        <!-- Flex container for equal height -->
        <!-- Directory Edit -->
        <div
          v-if="isDirectoryVisible"
          class="flex-1 border border-gray-200 rounded-lg flex flex-col"
        >
          <!-- Flex to keep height equal -->
          <div class="flex-1">
            <!-- Allow this div to grow -->
            <ViewDirectory
              :setErrorMessage="setErrorMessage"
              @hideWidgets="hideWidgets"
            />
          </div>
        </div>

        <!-- Widget Edit -->
        <div
          v-if="isWidgetVisible"
          class="flex-1 border border-gray-200 rounded-lg flex flex-col"
        >
          <!-- Flex to keep height equal -->
          <div class="flex-1">
            <!-- Allow this div to grow -->
            <ViewWidget @hideDirectory="hideDirectory" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ViewDirectory from "@/components/dashboard/programs/directory-and-widget/ViewDirectory.vue";
import ViewWidget from "@/components/dashboard/programs/directory-and-widget/ViewWidget.vue";

export default defineComponent({
  name: "DirectoryAndWidgetContainer",
  components: {
    ViewDirectory,
    ViewWidget,
  },
  setup() {
    const isWidgetVisible = ref(true);
    const isDirectoryVisible = ref(true);
    const errorMessage = ref("");

    const hideWidgets = (value: boolean) => {
      isWidgetVisible.value = !value;
    };

    const hideDirectory = (value: boolean) => {
      isDirectoryVisible.value = !value;
    };

    const setErrorMessage = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    return {
      hideWidgets,
      hideDirectory,
      isWidgetVisible,
      isDirectoryVisible,
      errorMessage,
      setErrorMessage,
    };
  },
});
</script>
