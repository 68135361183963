import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '../../../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex justify-center mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = {
  key: 1,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_4 = { class: "flex flex-wrap min-h-screen" }
const _hoisted_5 = { class: "flex flex-col w-[80%] items-start pt-4 border-t border-gray-200 gap-4" }
const _hoisted_6 = { class: "flex flex-wrap gap-4" }
const _hoisted_7 = { class: "flex flex-col w-[20%] border-l border-t border-gray-200" }
const _hoisted_8 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HubspotCard = _resolveComponent("HubspotCard")!
  const _component_SalesforceCard = _resolveComponent("SalesforceCard")!
  const _component_DetailsSection = _resolveComponent("DetailsSection")!
  const _component_SyncSalesforceModal = _resolveComponent("SyncSalesforceModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Error Icon",
                class: "w-4 h-4"
              })
            ], -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col text-left gap-2" }, [
          _createElementVNode("span", { class: "text-black text-sm font-medium leading-5" }, " Integration "),
          _createElementVNode("span", { class: "text-gray-500 text-sm font-normal leading-6" }, " Integrate your SlashExperts program with CRM. It only takes 5 minutes and no admin access required. It's easy! ")
        ], -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_HubspotCard),
          _createVNode(_component_SalesforceCard, {
            salesforceSynced: _ctx.salesforceSynced,
            loading: _ctx.salesforceLoading,
            connectSalesforce: _ctx.connectSalesforce
          }, null, 8, ["salesforceSynced", "loading", "connectSalesforce"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_DetailsSection)
        ])
      ])
    ]),
    _createVNode(_component_SyncSalesforceModal, {
      isVisible: _ctx.isSyncSalesforceModalVisible,
      providerAccountName: _ctx.salesforceName,
      providerAccountEmail: _ctx.salesforceEmail,
      programId: _ctx.userStore.selectedProgram?.id || '',
      onClose: _ctx.closeModal,
      onUpdatedSettings: _ctx.salesforceMappingUpdated
    }, null, 8, ["isVisible", "providerAccountName", "providerAccountEmail", "programId", "onClose", "onUpdatedSettings"])
  ], 64))
}