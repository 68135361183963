<template>
  <div class="w-full p-6 bg-gray-50 shadow-md rounded-md">
    <div v-if="paymentError" class="mt-3 text-red-500 mb-2">
      {{ paymentError }}
    </div>

    <div class="mb-4">
      <BaseLabel
        forAttr="cardNumber"
        text="Card Number"
        class="block text-sm font-medium text-gray-700"
      />
      <div
        id="card-number"
        class="mt-1 p-2 border border-gray-300 rounded-md"
      ></div>
    </div>

    <div class="flex flex-row gap-3">
      <div class="flex flex-col mb-4 w-1/2">
        <BaseLabel
          forAttr="expirationDate"
          text="Expiration Date"
          class="block text-sm font-medium text-gray-700"
        />
        <div
          id="card-expiry"
          class="mt-1 p-2 border border-gray-300 rounded-md"
        ></div>
      </div>

      <div class="flex flex-col mb-4 w-1/2">
        <BaseLabel
          forAttr="cvc"
          text="CVC"
          class="block text-sm font-medium text-gray-700"
        />
        <div
          id="card-cvc"
          class="mt-1 p-2 border border-gray-300 rounded-md"
        ></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import {
  loadStripe,
  StripeElements,
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from "@stripe/stripe-js";
import BaseLabel from "@/components/shared/BaseLabel.vue";

export default defineComponent({
  name: "StripeCardFields",
  components: {
    BaseLabel,
  },
  emits: ["paymentMethodId", "error"],
  setup(_, { emit }) {
    const stripePromise = loadStripe(
      process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    );
    const elements = ref<StripeElements | null>(null);
    const cardNumberElement = ref<StripeCardNumberElement | null>(null);
    const cardExpiryElement = ref<StripeCardExpiryElement | null>(null);
    const cardCvcElement = ref<StripeCardCvcElement | null>(null);
    const paymentError = ref<string | null>(null);
    const paymentMethodId = ref<string | null>(null);

    onMounted(async () => {
      const stripe = await stripePromise;
      if (!stripe) {
        console.log("Stripe failed to load.");
        return;
      }

      elements.value = stripe.elements();

      cardNumberElement.value = elements.value.create("cardNumber");
      cardNumberElement.value.mount("#card-number");

      cardExpiryElement.value = elements.value.create("cardExpiry");
      cardExpiryElement.value.mount("#card-expiry");

      cardCvcElement.value = elements.value.create("cardCvc");
      cardCvcElement.value.mount("#card-cvc");
    });

    document.addEventListener("generatePaymentMethodId", () => {
      handleSubmit();
    });

    const handleSubmit = async () => {
      paymentError.value = "";

      const stripe = await stripePromise;
      if (!stripe || !elements.value) {
        console.log("Stripe is not initialized properly.");
        return;
      }

      const cardNumberElement = elements.value.getElement("cardNumber");
      if (!cardNumberElement) {
        console.log("Card number element is not available.");
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (error) {
        emit("error", error.message);
      } else if (paymentMethod) {
        emit("paymentMethodId", paymentMethod.id);
      }
    };

    return {
      cardNumberElement,
      cardExpiryElement,
      cardCvcElement,
      handleSubmit,
      paymentError,
      paymentMethodId,
    };
  },
});
</script>
