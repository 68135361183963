import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_2 = { class: "flex flex-col flex-1" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  key: 0,
  class: "absolute z-50 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto w-full"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "px-4 py-2 text-gray-500"
}
const _hoisted_8 = { class: "flex justify-center" }
const _hoisted_9 = {
  key: 1,
  class: "flex flex-col inline-flex"
}
const _hoisted_10 = { class: "flex items-center bg-gray-200 rounded-md px-2 py-1 mb-2 inline-flex" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "text-sm font-semibold" }
const _hoisted_13 = { class: "flex flex-col flex-1" }
const _hoisted_14 = {
  key: 0,
  class: "flex flex-wrap gap-4 px-2 pt-2 pb-8 w-full text-xs leading-none text-gray-500 bg-gray-50 rounded-md min-h-12 overflow-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_AddProgramModal = _resolveComponent("AddProgramModal")!
  const _component_SoftwareCard = _resolveComponent("SoftwareCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoadingPrograms)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[5] || (_cache[5] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
          type: "text",
          placeholder: "Search for software...",
          onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
          class: "px-3.5 py-2.5 w-full text-sm leading-none bg-white rounded-md border border-gray-300 shadow-sm"
        }, null, 544), [
          [_vModelText, _ctx.searchQuery]
        ]),
        (_ctx.isFocused && _ctx.searchQuery.length > 0 && !_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitedResults, (card, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  onClick: ($event: any) => (_ctx.selectProgram(card)),
                  class: "flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-left"
                }, [
                  _createElementVNode("img", {
                    src: _ctx.programLogo(card),
                    alt: "Logo",
                    class: "w-8 h-8 mr-2 rounded-full"
                  }, null, 8, _hoisted_6),
                  _createTextVNode(" " + _toDisplayString(card.name), 1)
                ], 8, _hoisted_5))
              }), 128)),
              (_ctx.limitedResults.length === 0 && !_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-1 mb-1" }, [
                      _createElementVNode("p", { class: "text-left" }, "Software you are looking for is not found")
                    ], -1)),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_BaseButton, {
                        type: "button",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showCreateSoftwareModal = true)),
                        class: "border border-gray-300 px-4 py-2 text-base text-gray-700 rounded-md"
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" + Add New Software ")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedProgram)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-xs" }, "Selected software", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("img", {
                  src: _ctx.programLogo(_ctx.selectedProgram),
                  alt: "Logo",
                  class: "w-6 h-6 mr-1 rounded-full"
                }, null, 8, _hoisted_11),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.selectedProgram.name), 1),
                _createVNode(_component_BaseButton, {
                  type: "button",
                  onClick: _ctx.handleRemoveCard,
                  class: "ml-2 text-xs text-gray-500"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" x ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_AddProgramModal, {
          isVisible: _ctx.showCreateSoftwareModal,
          "onUpdate:isVisible": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCreateSoftwareModal = $event)),
          onProgramAdded: _ctx.handleProgramCreated
        }, null, 8, ["isVisible", "onProgramAdded"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (
          _ctx.programAdded &&
          (!_ctx.limitedResults ||
            !_ctx.searchQuery ||
            !_ctx.isFocused ||
            (_ctx.limitedResults.length === 0 && !_ctx.programAdded))
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createElementVNode("div", null, [
                _createVNode(_component_SoftwareCard, {
                  id: _ctx.programAdded?.id,
                  name: _ctx.programAdded?.name,
                  experts: _ctx.programAdded?.experts,
                  logo: _ctx.programLogo(_ctx.programAdded),
                  isAdded: "",
                  onRemoveCard: _ctx.handleRemoveCard
                }, null, 8, ["id", "name", "experts", "logo", "onRemoveCard"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}