import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withKeys as _withKeys, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition } from "vue"
import _imports_0 from '../../assets/shared/failure.svg'


const _hoisted_1 = { class: "flex flex-col min-h-screen bg-gray-100" }
const _hoisted_2 = {
  key: 0,
  class: "fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
}
const _hoisted_3 = {
  key: 1,
  class: "flex flex-grow max-md:flex-col"
}
const _hoisted_4 = { class: "flex flex-col w-1/5 max-md:ml-0 max-md:w-full bg-primary-purple" }
const _hoisted_5 = { class: "flex flex-col mt-14 flex-grow" }
const _hoisted_6 = { class: "flex flex-col w-full md:w-4/5 justify-start pt-12" }
const _hoisted_7 = { class: "flex flex-col items-start w-full gap-3 px-6 sm:px-24 text-left" }
const _hoisted_8 = { class: "flex justify-center mb-4" }
const _hoisted_9 = {
  key: 0,
  role: "alert",
  class: "flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap",
  style: {"max-height":"80px","overflow-y":"auto"}
}
const _hoisted_10 = { class: "flex flex-col bg-white rounded-lg p-0.5 w-full" }
const _hoisted_11 = {
  key: 0,
  class: "text-xs mt-1 text-gray-400 text-red-500"
}
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "flex flex-wrap justify-center items-center gap-2" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "flex flex-col mx-2"
}
const _hoisted_16 = { class: "flex flex-wrap justify-center mt-auto items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepsList = _resolveComponent("StepsList")!
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_InviteEmails = _resolveComponent("InviteEmails")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_FooterSection = _resolveComponent("FooterSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple" }, null, -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("aside", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-col ml-10 text-left" }, [
                _createElementVNode("span", { class: "text-white text-3xl font-semibold leading-9" }, [
                  _createTextVNode(" Get Started "),
                  _createElementVNode("br")
                ])
              ], -1)),
              _createVNode(_component_StepsList, { currentStep: 5 })
            ])
          ]),
          _createElementVNode("main", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex flex-col mb-1" }, [
                _createElementVNode("span", { class: "text-3xl leading-9 font-semibold text-neutral-800 font-inter" }, "Invite Experts"),
                _createElementVNode("span", { class: "text-sm leading-5 font-medium text-neutral-800" }, "Almost done! Invite experts to the program")
              ], -1)),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.errorMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full" }, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "Error Icon",
                          class: "w-4 h-4"
                        })
                      ], -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.errorMessage), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "flex flex-col" }, [
                _createElementVNode("span", { class: "text-sm leading-5 font-medium text-neutral-800" }, "Invite Email"),
                _createElementVNode("span", { class: "text-xs leading-5 font-medium text-neutral-800" }, "Enter email address then press enter to add more.")
              ], -1)),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_BaseInput, {
                  id: "email",
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  type: "email",
                  onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.addEmail()), ["enter"])),
                  onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleEmailInput())),
                  placeholder: "E.g: you@example.com",
                  className: {
                'flex-grow px-3.5 py-2.5 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full focus:outline-none': true,
                'border-red-500': _ctx.emailWarning,
                'border-gray-300': !_ctx.emailWarning,
              }
                }, null, 8, ["modelValue", "className"]),
                (_ctx.emailWarning)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.emailWarningMessage), 1))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_InviteEmails, {
                emailsList: _ctx.emailsList,
                sendInviteLoading: _ctx.sendInviteLoading,
                sentSuccessfully: _ctx.sentSuccessfully,
                inviteSentMessage: _ctx.inviteSentMessage,
                onRemoveEmail: _ctx.removeEmail,
                onSendInvite: _ctx.callSendInvite
              }, null, 8, ["emailsList", "sendInviteLoading", "sentSuccessfully", "inviteSentMessage", "onRemoveEmail", "onSendInvite"]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-sm leading-5 font-medium text-neutral-800" }, "Or Invite via Link", -1)),
                  _createVNode(_component_BaseButton, {
                    type: "button",
                    class: _normalizeClass(["flex justify-center items-center py-2 px-8 rounded-md text-sm font-medium text-white", {
                  'bg-indigo-100': _ctx.inviteLink !== '',
                  'bg-gray-300': _ctx.inviteLink === '',
                }]),
                    style: {"width":"auto"},
                    disabled: _ctx.inviteLink === '',
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyToClipboard()))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["flex items-center", {
                    'text-primary-purple': _ctx.inviteLink !== '',
                    'text-gray-500': _ctx.inviteLink === '',
                  }])
                      }, [
                        _createElementVNode("div", {
                          innerHTML: _ctx.NOTE_ICON,
                          class: "mr-2"
                        }, null, 8, _hoisted_14),
                        _cache[7] || (_cache[7] = _createElementVNode("span", null, "Generate Link", -1))
                      ], 2)
                    ]),
                    _: 1
                  }, 8, ["class", "disabled"]),
                  _createVNode(_Transition, {
                    "enter-active-class": "transition ease-in duration-300",
                    "leave-active-class": "transition ease-out duration-300",
                    "enter-from-class": "opacity-0",
                    "enter-to-class": "opacity-100",
                    "leave-from-class": "opacity-100",
                    "leave-to-class": "opacity-0"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.inviteLinkCopied)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[8] || (_cache[8] = [
                            _createElementVNode("span", { class: "text-sm leading-5 font-medium text-primary-purple" }, " Link Copied! ", -1)
                          ])))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("footer", _hoisted_16, [
              _createVNode(_component_FooterSection, {
                isDisabled: _ctx.isDisabled,
                onNext: _ctx.goNext
              }, null, 8, ["isDisabled", "onNext"])
            ])
          ])
        ]))
  ]))
}