import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" }
const _hoisted_2 = { class: "bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative z-60" }
const _hoisted_3 = { class: "flex flex-col gap-3" }
const _hoisted_4 = { class: "flex flex-col gap-3" }
const _hoisted_5 = {
  class: "text-left text-gray-500 text-xs font-medium leading-4 break-words whitespace-pre-wrap",
  style: {"word-wrap":"break-word"}
}
const _hoisted_6 = { class: "flex flex-wrap justify-end gap-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "absolute top-2 right-2 text-gray-500 hover:text-gray-700",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
      }, " ✕ "),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-800 text-base font-semibold leading-6 text-left" }, "Summary", -1)),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.profile?.userProgramProfile[0]?.summary), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BaseButton, {
            type: "button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleBookMeeting(_ctx.profile?.uuid || ''))),
            class: "bg-gray-200 text-black px-4 py-2 rounded-md text-sm font-medium leading-5"
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Book A Meeting")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}