<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col">
      <p
        class="text-gray-900 font-sans text-xl font-bold leading-7 text-left pl-4"
      >
        Select a Date & Time
      </p>
    </div>
    <div
      class="flex flex-col sm:flex-row gap-4 justify-center items-center sm:items-start"
    >
      <div class="flex flex-col w-full">
        <div class="flex flex-col w-full justify-center items-center relative">
          <!-- Loader Overlay -->
          <div
            v-if="isLoading"
            class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10"
          >
            <div
              class="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"
            ></div>
          </div>
          <!-- Calendar -->
          <div class="w-full my-calendar">
            <VCalendar
              v-model.string="selectedDate"
              borderless
              :attributes="attributes"
              :min-date="minimumDate"
              @dayclick="handleDayClick"
              @did-move="onMonthChanged"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 text-left">
          <div class="flex flex-col">
            <span class="text-gray-900 font-inter text-base font-bold leading-6"
              >Current Timezone</span
            >
          </div>
          <div class="flex wrap gap-2">
            <div v-html="GLOBE_ICON" class="w-4 h-4 text-gray-900"></div>
            <span
              class="text-gray-900 text-center font-inter text-sm font-normal leading-5"
              >{{ timeZone }} {{ utcOffset }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="flex flex-col w-full"
        v-if="selectedDate && isTodayOrFuture(selectedDate)"
      >
        <div class="flex flex-col w-full gap-3">
          <!-- Calendar -->
          <div class="flex flex-col w-full">
            <span>{{ formattedDate(selectedDate) }}</span>
          </div>
          <div
            class="flex flex-col w-full gap-2 overflow-y-auto min-h-[15vh] max-h-[40vh] overflow-x-hidden relative"
          >
            <div
              v-if="isLoadingTimeSlots"
              class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-10"
            >
              <div
                class="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full animate-spin"
              ></div>
            </div>
            <div v-if="dailyAvailableSlots.length > 0">
              <div
                v-for="(slot, index) in dailyAvailableSlots"
                :key="index"
                class="flex flex-col"
              >
                <div v-if="pickedSlotIndex !== index">
                  <BaseButton
                    type="button"
                    class="text-gray-700 md:px-10 lg:px-20 py-2 rounded-md border border-gray-200 w-full text-center font-sans text-base font-normal leading-6 whitespace-nowrap"
                    @click="pickedSlotIndex = index"
                    >{{ displayStartTime(slot.start) }}</BaseButton
                  >
                </div>
                <div
                  v-else
                  class="flex flex-row justify-center gap-2 transition-transform transform"
                  :class="{
                    'animate-slide-in': pickedSlotIndex === index,
                    'animate-slide-out': pickedSlotIndex !== index,
                  }"
                >
                  <BaseButton
                    type="button"
                    disabled
                    class="text-gray-700 bg-gray-300 text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
                  >
                    {{ displayStartTime(slot.start) }}
                  </BaseButton>
                  <BaseButton
                    type="button"
                    @click="next(slot.start)"
                    class="text-white bg-primary-purple text-sm px-4 py-2 w-full rounded-md border border-gray-200 font-sans text-base font-normal leading-6 whitespace-nowrap"
                  >
                    Next
                  </BaseButton>
                </div>
              </div>
            </div>
            <div v-else class="flex flex-col w-full text-gray-500 text-center">
              No slots available
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { CalendarDay, Page } from "@/types/vcalendar-types";
import { googleFontFamiliesLink } from "@/data/expert-program/font-families";
import BaseButton from "../shared/BaseButton.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { getCurrentTimeZoneInfo } from "@/utils/dateAndTimeUtils";
import { GLOBE_ICON } from "@/assets/svg/shared/svg-constants";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useAttendeeStore } from "@/stores/attendee";

interface Attribute {
  highlight: {
    color: string;
    fillMode: string;
  };
  dates: Date[];
}

const GET_MONTHLY_AVAILABILITY = gql`
  query getUserMonthlyAvailabilities(
    $userUuid: String!
    $month: Int!
    $year: Int!
  ) {
    getUserMonthlyAvailabilities(
      userUuid: $userUuid
      month: $month
      year: $year
    ) {
      availabilityByDate
    }
  }
`;

const GET_DAILY_AVAILABLE_SLOTS = gql`
  query getUserDailyAvailableSlots(
    $userUuid: String!
    $timezone: String
    $date: String
    $day: String
  ) {
    getUserDailyAvailableSlots(
      userUuid: $userUuid
      timezone: $timezone
      date: $date
      day: $day
    ) {
      success
      data {
        start
        end
      }
    }
  }
`;

export default defineComponent({
  name: "MeetingCalendar",
  components: {
    BaseButton,
  },
  mounted() {
    const link = document.createElement("link");
    link.href = googleFontFamiliesLink;
    link.rel = "stylesheet";
    document.head.appendChild(link);
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userId = ref("");
    const selectedDate = ref();
    const selectedStartTime = ref();
    const timeZone = ref("");
    const utcOffset = ref("");
    const pickedSlotIndex = ref<null | number>(null);
    const attendeeStore = useAttendeeStore();
    const minimumDate = ref(new Date(Date.now()));
    const dailyAvailableSlots = ref<{ start: string; end: string }[]>([]);
    const attributes = ref<Attribute[]>([
      {
        highlight: {
          color: "purple",
          fillMode: "light",
        },
        dates: [],
      },
      {
        highlight: {
          color: "purple",
          fillMode: "solid",
        },
        dates: [],
      },
    ]);

    const isLoading = ref(true);
    const isLoadingTimeSlots = ref(false);
    const availabilityByDate = ref<
      Record<
        string,
        { day: string; available_slots: { start: string; end: string }[] }
      >
    >({});
    const setTimeZone = () => {
      const timeZoneInfo = getCurrentTimeZoneInfo();
      timeZone.value = timeZoneInfo.timeZone;
      utcOffset.value = timeZoneInfo.utcOffset;
    };

    const fetchMonthlyAvailability = (month: number, year: number) => {
      isLoading.value = true;

      const { onResult, onError } = useQuery(
        GET_MONTHLY_AVAILABILITY,
        {
          userUuid: userId.value,
          month: month,
          year: year,
        },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        if (result.data && result.data.getUserMonthlyAvailabilities) {
          availabilityByDate.value =
            result.data.getUserMonthlyAvailabilities.availabilityByDate;
          console.log("availabilityByDate", availabilityByDate.value);
          const dateKeys = Object.keys(availabilityByDate.value).map(
            (dateString) => {
              return new Date(dateString);
            }
          );
          attributes.value[0].dates = dateKeys;
          isLoading.value = false;
        }
      });

      onError((error) => {
        if (error) {
          console.log("error", error);
          isLoading.value = false;
        }
      });
    };

    const handleDayClick = (day: CalendarDay) => {
      pickedSlotIndex.value = null;
      console.log("check day present ", availabilityByDate.value);
      if (!Object.keys(availabilityByDate.value).includes(day.id)) {
        selectedDate.value = null;
        return;
      }
      selectedDate.value = day.date;
      attributes.value[1].dates = [selectedDate.value];
      fetchDailyAvailableSlots(day);
    };

    const fetchDailyAvailableSlots = (day: CalendarDay) => {
      isLoadingTimeSlots.value = true;

      const { onResult, onError } = useQuery(
        GET_DAILY_AVAILABLE_SLOTS,
        {
          userUuid: userId.value,
          timezone: timeZone.value,
          date: day.id,
          day: day.date.toLocaleDateString("en-US", { weekday: "long" }),
        },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        if (result.data && result.data.getUserDailyAvailableSlots.success) {
          let futureSlots = filterFutureTimeSlots(
            result.data.getUserDailyAvailableSlots.data,
            day
          );
          dailyAvailableSlots.value = futureSlots;
          isLoadingTimeSlots.value = false;
        } else {
          console.log("no slots available");
          dailyAvailableSlots.value = [];
          isLoadingTimeSlots.value = false;
        }
      });

      onError((error) => {
        if (error) {
          console.log("error", error);
          isLoadingTimeSlots.value = false;
        }
      });
    };

    const isSlotInTheFuture = (start: string, day: Date): boolean => {
      const currentTime = new Date();
      const slotTime = convertToLocalDate(start, day);
      return slotTime > currentTime;
    };

    const filterFutureTimeSlots = (
      slots: { start: string; end: string }[],
      day: CalendarDay
    ) => {
      let filteredSlots = [];

      filteredSlots = slots.filter((slot) => {
        return isSlotInTheFuture(slot.start, day.date);
      });

      return filteredSlots;
    };

    const isTodayOrFuture = (dateToCheck: Date): boolean => {
      const inputDate = new Date(
        dateToCheck.getFullYear(),
        dateToCheck.getMonth(),
        dateToCheck.getDate()
      );
      const today = new Date();
      const todayOnly = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );

      return inputDate >= todayOnly;
    };

    const onMonthChanged = (eventData: Page[]) => {
      fetchMonthlyAvailability(eventData[0].month, eventData[0].year);
    };

    const formattedDate = (date: Date) => {
      date.setHours(0, 0, 0, 0);

      return date.toDateString();
    };

    const dateToString = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    const displayStartTime = (time: string): string => {
      let [hours, minutes] = time.split(":").map(Number);

      const period = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }

      return `${hours}:${String(minutes).padStart(2, "0")} ${period}`;
    };

    const next = (startTime: string) => {
      if (
        attendeeStore.bookMeetingName ||
        attendeeStore.bookMeetingEmail ||
        attendeeStore.bookMeetingNotes
      ) {
        attendeeStore.clearStore();
      }
      selectedStartTime.value = startTime;
      router.push({
        query: {
          ...router.currentRoute.value.query,
          startTime: startTime,
          date: dateToString(selectedDate.value),
        },
      });
    };

    const convertToLocalDate = (timeString: string, day: Date): Date => {
      const [time, modifier] = timeString.split(" ");
      let [hours, minutes] = time
        .split(":")
        .map((value) => value.padStart(2, "0"));

      if (modifier === "PM" && hours !== "12") {
        hours = (parseInt(hours, 10) + 12).toString();
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }

      const formattedDate = new Date(
        day.getFullYear(),
        day.getMonth(),
        day.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      );

      if (isNaN(formattedDate.getTime())) {
        throw new Error(`Invalid date: ${formattedDate}`);
      }

      return formattedDate;
    };

    onMounted(() => {
      userId.value =
        typeof route.query.expert === "string" ? route.query.expert : "";
      const [currentMonth, currentYear] = [
        new Date().getMonth() + 1,
        new Date().getFullYear(),
      ];

      setTimeZone();
      fetchMonthlyAvailability(currentMonth, currentYear);
    });

    return {
      attributes,
      // availableSlots,
      timeZone,
      utcOffset,
      isLoading,
      selectedDate,
      handleDayClick,
      onMonthChanged,
      minimumDate,
      isLoadingTimeSlots,
      formattedDate,
      pickedSlotIndex,
      next,
      displayStartTime,
      isTodayOrFuture,
      isSlotInTheFuture,
      dailyAvailableSlots,
      GLOBE_ICON,
    };
  },
});
</script>

<style scoped>
.purple {
  color: "#6A48F3";
}

.my-calendar :deep(.vc-title) {
  color: #1a1a1a;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.my-calendar :deep(.vc-weekday) {
  padding: 15px;
}

.my-calendar :deep(.vc-base-icon) {
  color: #6a48f3;
  background-color: rgba(106, 72, 243, 0.08);
  border-radius: 9999px;
}

.my-calendar :deep(.vc-nav-item.is-active) {
  background-color: #6a48f3;
}

.my-calendar :deep(.vc-nav-item.is-current) {
  color: #6a48f3;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.animate-slide-in {
  animation: slideIn 0.3s ease-out;
}

.animate-slide-out {
  animation: slideOut 0.3s ease-in;
}
</style>
