<template>
  <div class="flex flex-wrap gap-2 w-full justify-between p-8 bg-gray-100">
    <div class="flex flex-col text-left">
      <span class="text-gray-500 font-sans text-lg font-medium leading-6"
        >Return on Experts Spend</span
      >
      <span class="text-gray-900 font-sans text-2xl font-semibold leading-9"
        >Coming Soon</span
      >
    </div>
    <div class="flex">
      <div class="flex gap-2 pr-4 border-r border-gray-300">
        <div class="flex">
          <div
            class="flex justify-center items-center self-stretch px-3 w-12 h-12 bg-purple-100 rounded-md"
          >
            <div v-html="EARNINGS_ICON" class="text-primary-purple"></div>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="text-gray-500 font-sans text-lg font-medium leading-6"
            >Total Spend</span
          >
          <span class="text-gray-900 font-sans text-2xl font-semibold leading-9"
            >${{ totalSpend }}</span
          >
        </div>
      </div>
      <div class="flex gap-2 pl-4 text-left">
        <div class="flex">
          <div
            class="flex justify-center items-center self-stretch px-3 w-12 h-12 bg-purple-100 rounded-md"
          >
            <div v-html="BADGE_CHECK_ICON" class="text-primary-purple"></div>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="text-gray-500 font-sans text-lg font-medium leading-6"
            >Closed Deals</span
          >
          <span class="text-gray-900 font-sans text-2xl font-semibold leading-9"
            >Coming Soon</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  EARNINGS_ICON,
  BADGE_CHECK_ICON,
} from "@/assets/svg/shared/svg-constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnalyticsSummary",
  props: {
    totalSpend: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      EARNINGS_ICON,
      BADGE_CHECK_ICON,
    };
  },
});
</script>
