import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/shared/linkedin-icon.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "overflow-hidden"
}
const _hoisted_3 = { class: "overflow-hidden gap-1.5 self-stretch px-1.5 py-0.5 my-auto whitespace-nowrap rounded-sm bg-slate-50" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeeMoreModal = _resolveComponent("SeeMoreModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("article", {
      class: _normalizeClass(["flex flex-col grow shrink p-2 bg-white rounded-lg min-w-[10.5rem] max-w-[21rem] sm:min-w-[21rem] overflow-hidden", {
      'items-center justify-center text-center': _ctx.isCentered,
      'items-start text-left': !_ctx.isCentered,
    }]),
      style: _normalizeStyle({
      fontFamily: _ctx.fontFamily,
      fontWeight: _ctx.boldness,
      fontSize: _ctx.fontSize + 'px',
      height: !_ctx.isGridUneven ? _ctx.computedHeight : 'auto',
    })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex gap-2.5", {
        'items-start': !_ctx.isCentered,
        'justify-center items-center': _ctx.isCentered,
        [_ctx.profileImageAndNameLayoutClass]: true,
      }])
      }, [
        _createElementVNode("img", {
          src: _ctx.profile.imageUrl,
          class: _normalizeClass([_ctx.profileImageClass, "object-cover shrink-0 w-8 h-8"]),
          alt: ""
        }, null, 10, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass(["flex flex-row gap-4 items-center", {
          'justify-center': _ctx.isCentered,
        }])
        }, [
          _createElementVNode("h3", {
            class: _normalizeClass(["text-xs font-semibold leading-loose text-zinc-800", { 'text-center': _ctx.isCentered }]),
            style: _normalizeStyle({ fontSize: _ctx.fontSize + 'px' })
          }, _toDisplayString(_ctx.expertName(_ctx.profile)), 7),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "object-cover shrink-0 w-6 h-6",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLinkedinClick(_ctx.profile.linkedinUrl)))
          })
        ], 2)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex mb-2 pb-1 overflow-hidden pr-4", {
        'justify-center': _ctx.isCentered,
      }])
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["mt-3 text-xs leading-5 text-neutral-700 whitespace-normal break-words overflow-hidden", { 'text-center': _ctx.isCentered, 'text-left': !_ctx.isCentered }]),
          style: _normalizeStyle({ fontSize: _ctx.fontSize + 'px' })
        }, [
          _createTextVNode(_toDisplayString(_ctx.truncateText(_ctx.expertDescription(_ctx.profile))) + " ", 1),
          (_ctx.isTextTruncated(_ctx.expertDescription(_ctx.profile)))
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "text-primary-purple cursor-pointer",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSeeMore()))
              }, "See more"))
            : _createCommentVNode("", true)
        ], 6)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col justify-end w-full", {
        'items-center': _ctx.isCentered,
        'mt-auto': !_ctx.isGridUneven,
      }])
      }, [
        (_ctx.profile.country)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.profile.country), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["w-full", { 'flex justify-center': _ctx.isCentered }])
        }, [
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleBookMeeting(_ctx.profile.uuid))),
            class: "overflow-hidden gap-2 w-full py-1.5 pr-2.5 pl-2.5 mt-2.5 font-medium rounded shadow-sm",
            disabled: !_ctx.enableBookMeeting,
            style: _normalizeStyle({
            backgroundColor: _ctx.backgroundColor,
            color: _ctx.textColor,
          })
          }, " Book a Meeting ", 12, _hoisted_4)
        ], 2)
      ], 2)
    ], 6),
    (_ctx.isSeeMoreModalOpen)
      ? (_openBlock(), _createBlock(_component_SeeMoreModal, {
          key: 0,
          profile: _ctx.profile,
          "onUpdate:isVisible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSeeMoreModalOpen = $event)),
          "onUpdate:bookMeeting": _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleBookMeeting($event)))
        }, null, 8, ["profile"]))
      : _createCommentVNode("", true)
  ], 64))
}