import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ServerError,
} from "@apollo/client/core";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { provideApolloClient } from "@vue/apollo-composable";
import { useUserStore } from "@/stores/user";
import { onError } from "@apollo/client/link/error";

const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_API_TARGET,
});

const authLink = setContext((_, { headers }) => {
  const userStore = useUserStore();
  const token = userStore.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if ("statusCode" in networkError && networkError.statusCode === 401) {
      const serverError = networkError as ServerError;
      let errorMessage = "";
      if (serverError?.result && typeof serverError.result !== "string") {
        errorMessage = serverError.result.errors + " Please login.";
      } else {
        errorMessage = "Please login.";
      }
      const redirectURL = `${process.env.VUE_APP_SIGN_IN_FORM_URL}?error=${errorMessage}`;
      window.location.href = redirectURL;
      return;
    }
  }
});

const link = ApolloLink.from([errorLink, authLink, uploadLink]);

const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export function setupApolloClient() {
  provideApolloClient(apolloClient);
}
