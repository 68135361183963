import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-center gap-2" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "text-sm font-medium leading-5 text-zinc-800 text-left" }
const _hoisted_6 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_UpdateAccountBalanceModal = _resolveComponent("UpdateAccountBalanceModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        innerHTML: _ctx.EARNINGS_ICON,
        class: "text-primary-purple"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, " Available Balance: $" + _toDisplayString(_ctx.userStore.availableBalance), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseButton, {
          type: "button",
          class: "flex flex-col justify-center items-center p-2 w-full text-white bg-primary-purple rounded-lg text-sm font-medium leading-none text-center",
          onClick: _ctx.addBalance
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Add Balance ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _createVNode(_component_UpdateAccountBalanceModal, {
      isVisible: _ctx.addAccountBalanceModal,
      "onUpdate:isVisible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.addAccountBalanceModal = $event)),
      onUpdateAccountBalance: _ctx.handleUpdateAccountBalance
    }, null, 8, ["isVisible", "onUpdateAccountBalance"])
  ], 64))
}