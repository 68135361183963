<template>
  <nav class="flex flex-col items-start pt-6 rounded-none">
    <ul class="flex flex-col items-start self-stretch mt-0">
      <li
        v-for="(step, index) in steps"
        :key="index"
        class="flex gap-3 items-center self-stretch text-sm font-medium leading-none"
        :class="{
          'text-white': currentStep === index,
          'text-violet-300': currentStep !== index,
        }"
      >
        <div class="flex flex-col">
          <div class="flex flex-row">
            <div
              v-html="stepIcon(index)"
              :class="stepClass(index)"
              class="mr-1"
            ></div>
            <span class="self-stretch my-auto text-left">{{ step }}</span>
          </div>

          <div>
            <div
              v-if="index < steps.length - 1"
              class="z-10 shrink-0 ml-2.5 w-px h-7 bg-violet-300 border border-violet-300 border-solid"
            ></div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import {
  CHECK_CIRCLE,
  INNER_WHITE_BULLET_POINT_ICON,
  OUTER_WHITE_BULLET_POINT_ICON,
} from "@/assets/svg/shared/svg-constants";
import { defineComponent } from "vue";

export default defineComponent({
  name: "StepsList",
  props: {
    currentStep: {
      type: Number,
    },
  },
  setup(props) {
    const steps = [
      "Expert Program Details",
      "Billing",
      "Directory",
      "Widget",
      "Integration",
      "Invite Expert",
    ];

    const stepIcon = (index: number) => {
      if (props.currentStep !== undefined && props.currentStep !== null) {
        if (index < props.currentStep) {
          return CHECK_CIRCLE;
        } else if (props.currentStep === index) {
          return OUTER_WHITE_BULLET_POINT_ICON;
        } else {
          return INNER_WHITE_BULLET_POINT_ICON;
        }
      }
    };

    const stepClass = (index: number) => {
      if (props.currentStep !== undefined && props.currentStep !== null) {
        if (index < props.currentStep) {
          return "text-white";
        } else {
          return "text-primary-purple";
        }
      }
    };

    return { steps, stepIcon, stepClass };
  },
});
</script>
