<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative z-60"
    >
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="closeModal"
      >
        ✕
      </button>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-3">
          <span
            class="text-gray-800 text-base font-semibold leading-6 text-left"
            >Summary</span
          >
          <span
            class="text-left text-gray-500 text-xs font-medium leading-4 break-words whitespace-pre-wrap"
            style="word-wrap: break-word"
          >
            {{ profile?.userProgramProfile[0]?.summary }}
          </span>
        </div>
        <div class="flex flex-wrap justify-end gap-2 items-center">
          <BaseButton
            type="button"
            @click="handleBookMeeting(profile?.uuid || '')"
            class="bg-gray-200 text-black px-4 py-2 rounded-md text-sm font-medium leading-5"
            >Book A Meeting</BaseButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { Profile } from "@/types/directory-types";

export default defineComponent({
  name: "InviteEmailComponent",
  components: {
    BaseButton,
  },
  props: {
    profile: {
      type: Object as PropType<Profile | undefined>,
      required: false,
    },
  },
  emits: ["update:isVisible", "update:bookMeeting"],
  setup(_, { emit }) {
    const closeModal = () => {
      emit("update:isVisible", false);
    };

    const handleBookMeeting = (expertId: string) => {
      if (expertId.length > 0) {
        console.log("expertId is ", expertId);
        emit("update:bookMeeting", expertId);
      }
    };

    return {
      closeModal,
      handleBookMeeting,
    };
  },
});
</script>
